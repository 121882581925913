export const EVENT_ATTENDEE_DETAIL_SUCCESS = "EVENT_ATTENDEE_DETAIL_SUCCESS";
export const EVENT_ATTENDEE_DETAIL_FAIL = "EVENT_ATTENDEE_DETAIL_FAIL";
export const EVENT_ATTENDEE_DETAIL_REQUEST = "EVENT_ATTENDEE_DETAIL_REQUEST";

export const EVENT_LIST_REQUEST = "EVENT_LIST_REQUEST";
export const EVENT_LIST_SUCCESS = "EVENT_LIST_SUCCESS";
export const EVENT_LIST_FAIL = "EVENT_LIST_FAIL";

export const EVENT_DATA_REQUEST = "EVENT_DATA_REQUEST";
export const EVENT_DATA_SUCCESS = "EVENT_DATA_SUCCESS";
export const EVENT_DATA_FAIL = "EVENT_DATA_FAIL";

export const EVENT_SUMMARY_REQUEST = "EVENT_SUMMARY_REQUEST";
export const EVENT_SUMMARY_SUCCESS = "EVENT_SUMMARY_SUCCESS";
export const EVENT_SUMMARY_FAIL = "EVENT_SUMMARY_FAIL";


export const PAYMENT_STATUS_REQUEST = "PAYMENT_STATUS_REQUEST";
export const PAYMENT_STATUS_SUCCESS = "PAYMENT_STATUS_SUCCESS";
export const PAYMENT_STATUS_FAIL = "PAYMENT_STATUS_FAIL";