export const JOB_CANDIDATE_LIST_SUCCESS = "JOB_CANDIDATE_LIST_SUCCESS";
export const JOB_CANDIDATE_LIST_FAIL = "JOB_CANDIDATE_LIST_FAIL";
export const JOB_CANDIDATE_LIST_REQUEST = "JOB_CANDIDATE_LIST_REQUEST";

export const JOB_SUMMARY_REQUEST="JOB_SUMMARY_REQUEST";
export const JOB_SUMMARY_SUCCESS = "JOB_SUMMARY_SUCCESS";
export const JOB_SUMMARY_FAIL = "JOB_SUMMARY_FAIL";

export const JOB_LIST_SUCCESS = "JOB_LIST_SUCCESS";
export const JOB_LIST_FAIL = "JOB_LIST_FAIL";
export const JOB_LIST_REQUEST = "JOB_LIST_REQUEST";

export const CANDIDATE_DETAILS_SUCCESS = "CANDIDATE_DETAILS_SUCCESS";
export const CANDIDATE_DETAILS_FAIL = "CANDIDATE_DETAILS_FAIL";
export const CANDIDATE_DETAILS_REQUEST = "CANDIDATE_DETAILS_REQUEST";

export const BULK_RESUME_SUCCESS = "BULK_RESUME_SUCCESS";
export const BULK_RESUME_FAIL = "BULK_RESUME_FAIL";
export const BULK_RESUME_REQUEST = "BULK_RESUME_REQUEST";

export const APPLICANT_STATUS_SUCCESS = "APPLICANT_STATUS_SUCCESS";
export const APPLICANT_STATUS_FAIL = "APPLICANT_STATUS_FAIL";
export const APPLICANT_STATUS_REQUEST = "APPLICANT_STATUS_REQUEST";

export const APPLICANT_SCORE_SUCCESS = "APPLICANT_SCORE_SUCCESS";
export const APPLICANT_SCORE_FAIL = "APPLICANT_SCORE_FAIL";
export const APPLICANT_SCORE_REQUEST = "APPLICANT_SCORE_REQUEST";

export const SCORE_LIST_SUCCESS = "SCORE_LIST_SUCCESS";
export const SCORE_LIST_FAIL = "SCORE_LIST_FAIL";
export const SCORE_LIST_REQUEST = "SCORE_LIST_REQUEST";

export const SCORE_PARAM_UPDATE_SUCCESS = "SCORE_PARAM_UPDATE_SUCCESS";
export const SCORE_PARAM_UPDATE_FAIL = "SCORE_PARAM_UPDATE_FAIL";
export const SCORE_PARAM_UPDATE_REQUEST = "SCORE_PARAM_UPDATE_REQUEST";

export const SCORE_ENABLE_SUCCESS = "SCORE_ENABLE_SUCCESS";
export const SCORE_ENABLE_FAIL = "SCORE_ENABLE_FAIL";
export const SCORE_ENABLE_REQUEST = "SCORE_ENABLE_REQUEST";

export const RESUME_TOKEN_SUCCESS = "RESUME_TOKEN_SUCCESS";
export const RESUME_TOKEN_FAIL = "RESUME_TOKEN_FAIL";
export const RESUME_TOKEN_REQUEST = "RESUME_TOKEN_REQUEST";