import React, { useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

import {
  Alert,
  Button,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";

import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import { useDispatch, useSelector } from "react-redux";
import { signIn } from "../../redux/Actions/userActions";

const styles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: useTheme().spacing(2),
    margin: useTheme().spacing(2),
    backgroundColor: useTheme().palette.background.paper,
  },
  paper: {
    padding: useTheme().spacing(4),
    textAlign: "center",
    borderRadius: 18,
    color: useTheme().palette.text.secondary,
    background: "rgba(255, 255, 255, 0.5)",
    boxShadow: "0 4px 22px rgba(0, 0, 0, 0.1)",
    marginTop: 30,
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  h1: {
    background: "linear-gradient(to right, #CC7A64, #B756A9)",
    backgroundClip: "text",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    width: "fit-content",
    fontSize: 50,
    fontWeight: "700",
  },
  h6: {
    fontSize: 18,
    fontWeight: "500",
  },
  ul: {
    margin: "25px 20px",
  },
  li: {
    fontWeight: "500",
  },
  span: {
    fontWeight: "600",
    background: "linear-gradient(to right, #A83BDB, #20578A)",
    backgroundClip: "text",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    width: "fit-content",
  },
  h2: {
    fontWeight: "700",
    fontSize: 30,
    background: "linear-gradient(to right, #A83BDB, #20578A)",
    backgroundClip: "text",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    width: "fit-content",
  },
}));

const SignIn = () => {
  const classes = styles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { userInfo, loading: loadingDetails } = useSelector((state: any) => state.userDetails);

  const { loading, error: loginError } = useSelector(
    (state: any) => state.signIn
  );

  const [error, setError] = React.useState({
    status: false,
    message: "",
  });
  const [userData, setUserData] = React.useState({
    email: "",
    password: "",
  });
  const { email, password } = userData;
  const onChange = (input: any) => (e: any) => {
    if (input === "email") {
      setUserData({ ...userData, [input]: e.target.value.toLowerCase() });
    } else {
      setUserData({ ...userData, [input]: e.target.value });
    }
  };
  const onSubmit = async (e: any) => {
    e.preventDefault();
    if (email === "" || password === "") {
      setError({
        status: true,
        message: "Please enter your email and password",
      });
    } else if (
      !/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      )
    ) {
      setError({
        status: true,
        message: "Please enter a valid email",
      });
    } else {
      dispatch(signIn(userData));
    }
  };

  useEffect(() => {
    if (userInfo && location.search) {
      navigate(`${location.search.split("=")[1]}`);
    } else if (userInfo) {
      navigate("/dashboard");
    }
  }, [userInfo, location.search, loading, loadingDetails]);

  useEffect(() => {
    if (loginError) {
      setError({
        status: true,
        message: loginError?.data?.message,
      });
    }
  }, [loginError]);

  return (
    <>
      <div style={{ width: "80vw", margin: "auto" }} className="root">
        {/* <img className="left" src={left} /> */}
        <Grid container className={classes.center}>
          <Grid item xs={12} sm={12} md={6} lg={6} style={{marginTop: "100px"}}>
            <Paper elevation={3} className={classes.paper}>
              <Grid container spacing={3} className={classes.center}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <h2 style={{ color: "#183A59" }}>Sign In</h2>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    label="Email"
                    variant="outlined"
                    onChange={onChange("email")}
                    sx={{
                      width: {
                        xs: "90%",
                        sm: "90%",
                        md: "90%",
                        lg: "100%",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    label="Password"
                    variant="outlined"
                    type="password"
                    onChange={onChange("password")}
                    onKeyPress={(e) => e.key === "Enter" && onSubmit(e)}
                    sx={{
                      width: {
                        xs: "90%",
                        sm: "90%",
                        md: "90%",
                        lg: "100%",
                      },
                    }}
                  />
                  <Link to="/signin/forget-password">
                    <a style={{ color: "inherit" }}>Forgot password?</a>
                  </Link>
                </Grid>
                {error.status && (
                  <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 2 }}>
                    <Alert severity="error">{error.message}</Alert>
                  </Grid>
                )}
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={(e) => {
                        onSubmit(e);
                      }}
                    >
                      Sign In
                    </Button>
                  )}

                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    sx={{ mt: 1 }}
                  >
                    New to Elevate Career Network?{" "}
                    <a href="https://www.elevatecareernetwork.com/signup">Sign Up</a>
                  </Typography>
                </Grid>
                {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Divider> or </Divider>
                      </Grid>
                      <Grid item xs={7} sm={7} md={6} lg={6}>
                        <GoogleSignInButton />
                      </Grid>
                      <Grid item xs={7} sm={7} md={6} lg={6}>
                        <LinkedinSignInButton />
                      </Grid> */}
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        {/* <img className="right" src={right} /> */}
      </div>
    </>
  );
};

export default SignIn;
