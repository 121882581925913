import axios from "../axiosInterceptor";
import {
  JOB_LIST_FAIL,
  JOB_LIST_REQUEST,
  JOB_LIST_SUCCESS,
  JOB_CANDIDATE_LIST_SUCCESS,
  JOB_CANDIDATE_LIST_REQUEST,
  JOB_CANDIDATE_LIST_FAIL,
  CANDIDATE_DETAILS_FAIL,
  CANDIDATE_DETAILS_REQUEST,
  CANDIDATE_DETAILS_SUCCESS,
  BULK_RESUME_REQUEST,
  BULK_RESUME_SUCCESS,
  BULK_RESUME_FAIL,
  APPLICANT_STATUS_REQUEST,
  APPLICANT_STATUS_SUCCESS,
  APPLICANT_STATUS_FAIL,
  APPLICANT_SCORE_REQUEST,
  APPLICANT_SCORE_SUCCESS,
  APPLICANT_SCORE_FAIL,
  SCORE_LIST_REQUEST,
  SCORE_LIST_SUCCESS,
  SCORE_LIST_FAIL,
  SCORE_PARAM_UPDATE_REQUEST,
  SCORE_PARAM_UPDATE_SUCCESS,
  SCORE_PARAM_UPDATE_FAIL,
  SCORE_ENABLE_REQUEST,
  SCORE_ENABLE_SUCCESS,
  SCORE_ENABLE_FAIL,
  JOB_SUMMARY_REQUEST,
  JOB_SUMMARY_SUCCESS,
  JOB_SUMMARY_FAIL,
  RESUME_TOKEN_REQUEST,
  RESUME_TOKEN_SUCCESS,
  RESUME_TOKEN_FAIL,
} from "../Constants/jobConstants";

const BASE = process.env.REACT_APP_BACKEND;

export const getJobList = () => async (dispatch: any) => {
  try {
    dispatch({
      type: JOB_LIST_REQUEST,
    });

    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token")!)
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    };

    const { data } = await axios.get(`${BASE}/api/v1/joblisting/user`, config);

    // Dispatch the success action
    dispatch({
      type: JOB_LIST_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: JOB_LIST_FAIL,
      payload: error.message,
    });
  }
};

export const getCandidatesList = (id: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: JOB_CANDIDATE_LIST_REQUEST,
    });

    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token")!)
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    };

    const { data } = await axios.get(
      `${BASE}/api/v2/jobapplication/job/${id}`,
      config
    );

    // console.log(data)

    // Dispatch the success action
    dispatch({
      type: JOB_CANDIDATE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: JOB_CANDIDATE_LIST_FAIL,
      payload: error.message,
    });
  }
};

export const getCandidatesDetails = (id: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: CANDIDATE_DETAILS_REQUEST,
    });

    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token")!)
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    };

    const { data } = await axios.get(`${BASE}/api/v2/jobapplication/${id}`, config);

    // console.log(data)

    // Dispatch the success action
    dispatch({
      type: CANDIDATE_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: CANDIDATE_DETAILS_FAIL,
      payload: error.message,
    });
  }
};

export const bulkResumeDownload =
  (id: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: BULK_RESUME_REQUEST,
      });

      const {
        resumeToken: { resumeAccess },
      } = getState();

      const token = localStorage.getItem("token")
        ? JSON.parse(localStorage.getItem("token")!)
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${token.access_token}`,
        },
      };
      
      const res = await axios.get(
        `${BASE}/api/v1/jobapplication/job/download/${id}?access_token=${resumeAccess?.access_token}`,
        config
      );


      // Dispatch the success action
      dispatch({
        type: BULK_RESUME_SUCCESS,
        payload: res.data,
      });
    } catch (error: any) {
      dispatch({
        type: BULK_RESUME_FAIL,
        payload: error.message,
      });
    }
  };


export const putStatusUpdate = (application: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: APPLICANT_STATUS_REQUEST,
    });

    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token")!)
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    };

    const { data } = await axios.put(
      `${BASE}/api/v1/jobapplication/status`,
      application,
      config
    );

    // Dispatch the success action
    dispatch({
      type: APPLICANT_STATUS_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: APPLICANT_STATUS_FAIL,
      payload: error.message,
    });
  }
};

export const putScoreUpdate = (application: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: APPLICANT_SCORE_REQUEST,
    });

    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token")!)
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    };

    const { data } = await axios.put(
      `${BASE}/api/v1/jobapplication/score`,
      application,
      config
    );

    // Dispatch the success action
    dispatch({
      type: APPLICANT_SCORE_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: APPLICANT_SCORE_FAIL,
      payload: error.message,
    });
  }
};

export const getScoreParamList = () => async (dispatch: any) => {
  try {
    dispatch({
      type: SCORE_LIST_REQUEST,
    });

    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token")!)
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    };

    const { data } = await axios.get(
      `${BASE}/api/v1/block/6255c2656e3286a89ce0aa26`,
      config
    );

    // Dispatch the success action
    dispatch({
      type: SCORE_LIST_SUCCESS,
      payload: data.records,
    });
  } catch (error: any) {
    dispatch({
      type: SCORE_LIST_FAIL,
      payload: error.message,
    });
  }
};

export const putScoreParamUpdate = (application: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: SCORE_PARAM_UPDATE_REQUEST,
    });

    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token")!)
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    };

    const { data } = await axios.put(
      `${BASE}/api/v1/joblisting/score`,
      application,
      config
    );



    // Dispatch the success action
    dispatch({
      type: SCORE_PARAM_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: SCORE_PARAM_UPDATE_FAIL,
      payload: error.message,
    });
  }
};

export const enableScore = (application: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: SCORE_ENABLE_REQUEST,
    });

    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token")!)
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    };

    const { data } = await axios.put(
      `${BASE}/api/v1/joblisting/scoring`,
      application,
      config
    );

    // Dispatch the success action
    dispatch({
      type: SCORE_ENABLE_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: SCORE_ENABLE_FAIL,
      payload: error.message,
    });
  }
};

export const getJobsSummary = (id: any, group?: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: JOB_SUMMARY_REQUEST,
    });

    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token")!)
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    };

    const { data } = await axios.get(
      `${BASE}/api/v1/jobapplication/summary/${id}${group ? `?group=${group}` : ""}`,
      config
    );

    // Dispatch the success action
    dispatch({
      type: JOB_SUMMARY_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: JOB_SUMMARY_FAIL,
      payload: error.message,
    });
  }
};

export const getResumeToken = () => async (dispatch: any) => {
  try {
    dispatch({
      type: RESUME_TOKEN_REQUEST,
    });

    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token")!)
      : null;

    const { data } = await axios.post(`${BASE}/api/v1/auth/filestoken`, {
      access_token: token.access_token,
    });

    // Dispatch the success action
    dispatch({
      type: RESUME_TOKEN_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: RESUME_TOKEN_FAIL,
      payload: error.message,
    });
  }
};
