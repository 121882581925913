import { useState, useEffect } from "react";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { getEventData, getEventList } from "../../redux/Actions/eventActions";

import EventCandidateDetails from "../../Component/Events/EventCandidateDetails";
import EventsTable from "../../Component/Events/EventsTable";
import EventStatus from "../../Component/Events/PaymentStatus";

import LayoutsSide from "../../Component/Layout/LayoutsSide";

const Events = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  let id = searchParams.get("id");

  const { eventLists, loading, error } = useSelector(
    (state: any) => state.eventList
  );
  const { events } = useSelector((state: any) => state.eventData);
  const { Status } = useSelector((state: any) => state.paymentsStatus);
  const { userInfo } = useSelector((state: any) => state.userDetails);

  const [active, setActive] = useState(0);
  const [index, setIndex] = useState(-1);
  const [singleEventName, setSingleEventName] = useState();
  const [eventType, setEventType] = useState<any>();
  const [checkEvent, setCheckEvent] = useState<any>();
  const [AllEventList, setAllEventList] = useState<any>();

  let view = searchParams.get("view");
  let eventId = searchParams.get("eventId");
  let display = searchParams.get("display");

  useEffect(() => {
    dispatch(getEventList());
  }, [id, userInfo]);

  useEffect(() => {
    if (eventLists) {
      dispatch(getEventData(id));
    }
    setEventType(id);
  }, [Status, id, eventLists]);

  useEffect(() => {
    if (index >= 0 && index < events?.records.length) {
      navigate(
        `?view=details&eventId=${
          eventType === undefined ? eventId : eventType
        }&registrationId=${events?.records[index]._id}&index=${index}`
      );
    }
  }, [index]);

  useEffect(() => {
    eventLists?.records?.map((item: any) => {
      if (item?._id === eventType) {
        setCheckEvent(item?.paid_event);
      }
    });
  }, [id, events]);

  useEffect(() => {
    if (events) {
      setAllEventList(events);
    }
  }, [events]);

  useEffect(() => {
    if (!location.search) {
      navigate("?view=list&display=all");
    } else if (view === "details") {
      setActive(1);
    } else if (view === "list") {
      setActive(0);
    } else if (view === "payment") {
      setActive(2);
    }
  }, [location.search]);

  return (
    <div className="events">
      {active === 0 && (
        <EventsTable
          eventType={eventType}
          setEventType={setEventType}
          eventLists={eventLists}
          events={events}
          loading={loading}
          setIndex={setIndex}
          checkEvent={checkEvent}
        />
      )}
      {active === 1 && (
        <EventCandidateDetails
          index={index}
          setIndex={setIndex}
          maxLength={events?.records?.length}
        />
      )}
      {active === 2 && (
        <EventStatus
          events={AllEventList}
          loading={loading}
          eventId={id}
          eventLists={eventLists}
        />
      )}
    </div>
  );
};

export default LayoutsSide(Events);
