import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button, ButtonGroup } from "@mui/material";

import { jobAnalytics } from "../../redux/Actions/JobAnalyticAction";

import HeaderSection from "../../Component/DashBoard/JobSummary/HeaderSection";
import BodySection from "../../Component/DashBoard/JobSummary/BodySection";
import LayoutsSide from "../../Component/Layout/LayoutsSide";
import { getJobList } from "../../redux/Actions/jobActions";
import { getEventList } from "../../redux/Actions/eventActions";
import { useNavigate, useSearchParams } from "react-router-dom";

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  let view = searchParams.get("view");
  let listId = searchParams.get("id");

  const { jobList } = useSelector((state: any) => state.jobList);
  const { eventLists, loading, error } = useSelector(
    (state: any) => state.eventList
  );
  const { jobDataMiscellances } = useSelector(
    (state: any) => state.jobDataAnalyticsMiscellaneous
  );

  const [DataType, setDataType] = useState<any>("");
  const [typeFilter, setTypeFilter] = useState("JOB_APPLY");
  const [id, setId] = useState<any>("");
  const [listData, setListData] = useState<any>();
  const [timeFilter, setTimeFilter] = useState<any>(9999999999);

  useEffect(() => {
    dispatch(getJobList());
  }, []);

  useEffect(() => {
    dispatch(getEventList());
  }, []);

  useEffect(() => {
    if (view === null) {
      navigate("?view=jobs");
    }
  }, []);
  useEffect(() => {
    if (view === "jobs") {
      setListData(jobList);
      setDataType("JOB");
      if (jobDataMiscellances?.apply?.visible) {
        setTypeFilter("JOB_APPLY");
      } else if (jobDataMiscellances?.click?.visible) {
        setTypeFilter("JOB_APPLICATION");
      } else if (jobDataMiscellances?.view?.visible) {
        setTypeFilter("JOB_VIEW");
      } else {
        let id = jobDataMiscellances?.customLables
          ? jobDataMiscellances.customLables?.length === 0
            ? ""
            : jobDataMiscellances.customLables[0]?.id
          : undefined;

        setTypeFilter("CUSTOM_LABLE");
      }
    }
    if (view === "events") {
      setListData(eventLists);
      setDataType("EVENT");
      if (jobDataMiscellances?.apply?.visible) {
        setTypeFilter("EVENT_APPLY");
      } else if (jobDataMiscellances?.click?.visible) {
        setTypeFilter("EVENT_REGISTRATION");
      } else if (jobDataMiscellances?.view?.visible) {
        setTypeFilter("EVENT_VIEW");
      } else {
        let id = jobDataMiscellances?.customLables
          ? jobDataMiscellances.customLables?.length === 0
            ? ""
            : jobDataMiscellances.customLables[0]?.id
          : undefined;

        setTypeFilter("CUSTOM_LABLE");
      }
    }
  }, [view, eventLists, jobList, id, jobDataMiscellances]);

  const swicthHandler = (e: any) => {
    if (e === "jobs") {
      navigate("?view=jobs");
      window.location.reload();
    }
    if (e === "events") {
      navigate("?view=events");
      window.location.reload();
    }
  };

  console.log(jobDataMiscellances);

  return (
    <div className="dashboard">
      <div className="top">
        <h1 className="title">Analytics</h1>
        <div className="button-group">
          <Button
            onClick={() => swicthHandler("jobs")}
            className={view === "jobs" ? "button active" : "button"}
          >
            Jobs
          </Button>
          <Button
            onClick={() => swicthHandler("events")}
            className={view === "events" ? "button active" : "button"}
          >
            Event
          </Button>
        </div>
      </div>

      <HeaderSection
        listData={listData}
        setId={setId}
        setTimeFilter={setTimeFilter}
        DataType={DataType}
      />

      {listId != null && (
        <BodySection
          id={listId}
          timeFilter={timeFilter}
          DataType={DataType}
          setTypeFilter={setTypeFilter}
          typeFilter={typeFilter}
        />
      )}
      {listId === null && (
        <div className="default-text">
          <div>
            <img
              src="https://s1.ecnstatic.com/ecn/images/common/laptop.png"
              alt=""
            />
            <p>Please Select an {view === "jobs" ? "Jobs" : "Event"}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default LayoutsSide(Dashboard);
