import React, { useState, useEffect } from "react";

import {
  DataGridPro,
  GridCellParams,
  GridToolbar,
  MuiEvent,
  GridRenderCellParams,
  GridApi,
  GridColumns,
  gridColumnVisibilityModelSelector,
  GridEvents,
  GridRowGroupingModel,
  useGridApiRef,
  GridRowHeightParams,
} from "@mui/x-data-grid-pro";
import {
  Chip,
  Modal,
  Stack,
  Box,
  Select,
  MenuItem,
  InputLabel,
  Button,
  FormControl,
  TextField,
  IconButton,
  Dialog,
  AppBar,
  Toolbar,
  Slide,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";

import { styled } from "@mui/material/styles";
import { FilterList } from "@mui/icons-material";

import { Document, Page, pdfjs } from "react-pdf";

import { useDispatch, useSelector } from "react-redux";
import {
  bulkResumeDownload,
  getResumeToken,
} from "../../../redux/Actions/jobActions";
import { useNavigate } from "react-router-dom";

import moment from "moment-timezone";

import AffinityChips from "../../UI/AffinityChips";

const BASE = process.env.REACT_APP_BACKEND;

const AntDesignStyledDataGridPro = styled(DataGridPro)(({ theme }) => ({
  border: `1px solid ${theme.palette.mode === "light" ? "#f0f0f0" : "#303030"}`,
  color:
    theme.palette.mode === "light"
      ? "rgba(0,0,0,.85)"
      : "rgba(255,255,255,0.85)",
  fontFamily: "Poppins",
  WebkitFontSmoothing: "auto",
  letterSpacing: "normal",
  "& .MuiDataGrid-columnsContainer": {
    backgroundColor: theme.palette.mode === "light" ? "#fafafa" : "#1d1d1d",
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    borderRight: `1px solid ${
      theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
    }`,
  },
  "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
    borderBottom: `1px solid ${
      theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
    }`,
  },
  "& .MuiDataGrid-cell": {
    color:
      theme.palette.mode === "light"
        ? "rgba(0,0,0,.85)"
        : "rgba(255,255,255,0.85)",
    fontFamily: "Poppins",
    WebkitFontSmoothing: "auto",
    letterSpacing: "normal",
    "& .MuiDataGrid-columnsContainer": {
      backgroundColor: theme.palette.mode === "light" ? "#fafafa" : "#1d1d1d",
    },
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-colCell, .MuiDataGrid-cell": {
      borderRight: `1px solid ${
        theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
      }`,
    },
    "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
      borderBottom: `1px solid ${
        theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
      }`,
    },
    "& .MuiDataGrid-cell": {
      color:
        theme.palette.mode === "light"
          ? "rgba(0,0,0,.85)"
          : "rgba(255,255,255,0.65)",
    },
    "& .MuiPaginationItem-root": {
      borderRadius: 0,
    },
    "& .MuiCheckbox-root svg": {
      width: 10,
      height: 16,
      backgroundColor: "transparent",
      border: `1px solid ${
        theme.palette.mode === "light" ? "#d9d9d9" : "rgb(67, 67, 67)"
      }`,
      borderRadius: 2,
    },
    "& .MuiCheckbox-root svg path": {
      display: "none",
    },
    "& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg": {
      backgroundColor: "#1890ff",
      borderColor: "#1890ff",
    },
    "& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after": {
      position: "absolute",
      display: "table",
      border: "2px solid #fff",
      borderTop: 0,
      borderLeft: 0,
      transform: "rotate(45deg) translate(-50%,-50%)",
      opacity: 1,
      transition: "all .2s cubic-bezier(.12,.4,.29,1.46) .1s",
      content: '""',
      top: "50%",
      left: "39%",
      width: 5.71428571,
      height: 9.14285714,
    },
    "& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after":
      {
        width: 8,
        height: 8,
        backgroundColor: "#1890ff",
        transform: "none",
        top: "39%",
        border: 0,
      },
  },
  "css-1fajery-MuiDataGrid-root .MuiDataGrid-cell--textLeft": {
    justifyContent: "center!important",
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: 600,
  width: "97%",

  marginRight: "30px",
  marginTop: "50px!important",
  "& .MuiFormGroup-options": {
    alignItems: "center",
    paddingBottom: theme.spacing(1),
    "& > div": {
      minWidth: 200,
      margin: theme.spacing(2),
    },
  },
}));

type GridDataType = "Employee" | "Commodity";
type GridDataThemeOption = "default" | "ant";
function escapeRegExp(value: string): string {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

interface GridPaginationSettings {
  pagination: boolean;
  autoPageSize: boolean;
  pageSize: number | undefined;
}

interface GridConfigOptions {
  size: number;
  type: GridDataType;
  pagesize: number;
  theme: GridDataThemeOption;
}

interface GridToolbarContainerProps {
  onApply: (options: GridConfigOptions) => void;
  size: number;
  type: GridDataType;
  theme: GridDataThemeOption;
}
const INITIAL_GROUPING_COLUMN_MODEL = ["university"];

const useKeepGroupingColumnsHidden = (
  apiRef: React.MutableRefObject<GridApi>,
  columns: GridColumns,
  initialModel: GridRowGroupingModel,
  leafField?: string
) => {
  const prevModel = React.useRef(initialModel);

  React.useEffect(() => {
    apiRef.current.subscribeEvent(
      GridEvents.rowGroupingModelChange,
      (newModel) => {
        // console.log("newModel", newModel);
        const columnVisibilityModel = {
          ...gridColumnVisibilityModelSelector(apiRef),
        };
        newModel.forEach((field) => {
          if (!prevModel.current.includes(field)) {
            columnVisibilityModel[field] = false;
          }
        });
        prevModel.current.forEach((field) => {
          if (!newModel.includes(field)) {
            columnVisibilityModel[field] = true;
          }
        });
        apiRef.current.setColumnVisibilityModel(columnVisibilityModel);
        prevModel.current = newModel;
      }
    );
  }, [apiRef]);

  return React.useMemo(
    () =>
      columns.map((colDef) =>
        initialModel.includes(colDef.field) ||
        (leafField && colDef.field === leafField)
          ? { ...colDef, hide: true }
          : colDef
      ),
    [columns, initialModel, leafField]
  );
};

let randomCol: any = [
  "primary",
  "secondary",
  "error",
  "info",
  "success",
  "warning",
];

const columns = [
  // { field: '_id', headerName: 'ID', width: 300 },

  {
    field: "first_name",
    headerName: "Name",
    width: 140,
    // editable: true,
  },
  {
    field: "university",
    headerName: "University",

    width: 150,

    renderCell: (params: any) => (
      <Stack direction="row" spacing={1}>
        <Chip
          label={params.value}
          color={randomCol[params?.row?.random]}
          style={{
            backgroundColor: `${randomCol[params?.row?.random]}`,
            color: "#ffff",
            fontSize: "14px",
            height: "25px",
          }}
        />
      </Stack>
    ),
  },
  {
    field: "graduation_year",
    headerName: "Graduation Year",

    width: 200,
    // editable: true,
  },
  {
    field: "email",
    headerName: "Email",

    width: 200,
    // editable: true,
  },
  {
    field: "current_level",
    headerName: "Current Level",
    width: 130,
    // editable: true,
  },

  {
    field: "company",
    headerName: "Company",

    width: 200,
  },
  {
    field: "title",
    headerName: "Job Function",

    width: 200,
  },
  {
    field: "city",
    headerName: "City",

    width: 200,
  },

  {
    field: "partner_club",
    headerName: "University Partner Clubs",

    width: 200,
    // editable: true,
  },
  {
    field: "affinity",
    headerName: "Affinity Group",

    width: 290,
    renderCell: (params: any) => (
      <Stack direction="row" spacing={1}>
        {params?.row?.affinity !== "-"
          ? params?.row?.affinity?.map((affinity: string, index: number) => (
              <>
                <AffinityChips affinity={affinity} />
                <br></br>
              </>
            ))
          : params?.row?.question1}
      </Stack>
    ),
    // editable: true,
  },
];
const columns1 = [
  {
    field: "first_name",
    headerName: "Name",
    width: 140,
    // editable: true,
  },
  {
    field: "university",
    headerName: "University",

    width: 150,
  },
  {
    field: "graduation_year",
    headerName: "Graduation Year",

    width: 200,
    // editable: true,
  },
  {
    field: "email",
    headerName: "Email",

    width: 200,
    // editable: true,
  },
  {
    field: "current_level",
    headerName: "Current Level",
    width: 130,
    // editable: true,
  },

  {
    field: "company",
    headerName: "Company",

    width: 200,
  },
  {
    field: "title",
    headerName: "Job Function",

    width: 200,
  },
  {
    field: "city",
    headerName: "City",

    width: 200,
  },
  {
    field: "affinity",
    headerName: "Affinity Group",

    width: 290,

    // editable: true,
  },
  {
    field: "partner_club",
    headerName: "University Partner Clubs",

    width: 200,
    // editable: true,
  },
  {
    field: "affinity",
    headerName: "Affinity Group",

    width: 290,
    renderCell: (params: any) => (
      <Stack direction="row" spacing={1}>
        {params?.row?.affinity !== "-"
          ? params?.row?.affinity?.map((affinity: string, index: number) => (
              <>
                <AffinityChips affinity={affinity} />
                <br></br>
              </>
            ))
          : params?.row?.question1}
      </Stack>
    ),
    // editable: true,
  },
];

const JobUserTable: React.FC<{
  candidateList: any;
  loading: any;
  setJobType: any;
  jobType: any;
  setIndex: any;
  setViewType: any;
  viewType: any;
  jobDataMiscellances: any;
  setCustomLableId: any;
  customLableId: any;
}> = ({
  candidateList,
  viewType,
  loading,
  setJobType,
  jobType,
  setIndex,
  setViewType,
  jobDataMiscellances,
  setCustomLableId,
  customLableId,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const apiRef = useGridApiRef();

  const { resumeAccess, success } = useSelector(
    (state: any) => state.resumeToken
  );

  const [isAntDesign, setIsAntDesign] = React.useState<boolean>(false);
  const [type, setType] = React.useState<GridDataType>("Commodity");
  const [jobId, setJobId] = useState();
  const [OnClickGroupColumn, setOnClickGroupColumn] = useState("university");
  const [groupColumn, setGroupColumn] = useState<string>();
  const [randomGroup, setRandomGroup] = useState(false);
  const [tableView, settableView] = useState();
  const [rows, setRow] = useState([]);
  const [cols, setCols] = useState(columns);
  const [size, setSize] = useState(100);
  const [toggleOpen, setToggleOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [resume, setResume] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [subgroupColumn, setSubGroupColumn] = useState();
  const [searchRow, setSearchRow] = useState([]);
  const [checked, setChecked] = React.useState<any[]>([]);
  const [checkOpen, setCheckOpen] = useState(false);
  const [expandTable, setExpandTable] = useState(false);

  const [rowGroupingModel, setRowGroupingModel] = React.useState(
    INITIAL_GROUPING_COLUMN_MODEL
  );
  const windowSize = window.screen.availWidth;
  const token = localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token")!)
    : null;

  const data = {
    ...cols,
    ...rows,
  };
  // console.log("jobId", jobId);

  const [pagination, setPagination] = React.useState<GridPaginationSettings>({
    pagination: false,
    autoPageSize: false,
    pageSize: undefined,
  });

  const getActiveTheme = () => {
    return isAntDesign ? "ant" : "default";
  };

  const handleApplyClick = (settings: any) => {
    if (size !== settings.size) {
      setSize(settings.size);
    }

    if (type !== settings.type) {
      setType(settings.type);
    }

    if (getActiveTheme() !== settings.theme) {
      setIsAntDesign(!isAntDesign);
    }

    const newPaginationSettings: GridPaginationSettings = {
      pagination: settings.pagesize !== -1,
      autoPageSize: settings.pagesize === 0,
      pageSize: settings.pagesize > 0 ? settings.pagesize : undefined,
    };

    setPagination(
      (
        currentPaginationSettings: GridPaginationSettings
      ): GridPaginationSettings => {
        if (
          currentPaginationSettings.pagination ===
            newPaginationSettings.pagination &&
          currentPaginationSettings.autoPageSize ===
            newPaginationSettings.autoPageSize &&
          currentPaginationSettings.pageSize === newPaginationSettings.pageSize
        ) {
          return currentPaginationSettings;
        }
        return newPaginationSettings;
      }
    );
  };

  const DataGridComponent = isAntDesign
    ? AntDesignStyledDataGridPro
    : DataGridPro;

  useEffect(() => {
    if (candidateList) {
      let data1: any = [];
      candidateList?.records?.map((jobData: any, index: number) => {
        const endDate = jobData?.work_experiences[0]?.end_date
          ? moment(jobData?.work_experiences[0]?.end_date).format("ll")
          : "-";
        const startDate = moment(
          jobData?.work_experiences[0]?.start_date
        ).format("ll");

        const Current_level = () => {
          const month = new Date().getMonth();
          const year = new Date().getFullYear();
          if (
            (jobData?.education &&
              jobData?.education[0]?.graduation_year < year) ||
            (jobData?.education[0]?.graduation_year === year && month > 7)
          ) {
            if (jobData?.education[0]?.education_type === 0)
              return "Professional Undergraduate";
            else return "Professional MBA";
          } else if (jobData?.education[0]?.education_type === 0)
            return "Undergraduate";
          else return "MBA";
        };
        const Time = moment(jobData?.timestamp).format("LT");
        const rndInt = Math.floor(Math.random() * 5) + 1;

        const city1 = jobData?.work_experiences
          ? jobData?.work_experiences[0]?.city
          : "-";
        const company = jobData?.work_experiences
          ? jobData?.work_experiences[0]?.company
          : "-";
        const industry = jobData?.work_experiences
          ? jobData?.work_experiences[0]?.industry
          : "-";
        const title = jobData?.work_experiences
          ? jobData?.work_experiences[0]?.title
          : "-";
        const affinity = jobData?.created_by?.affinity_groups?.map(
          (aff: any) => {
            return aff;
          }
        );

        data1.push({
          _id: jobData?._id,
          index: index,
          first_name:
            jobData?.created_by?.first_name +
            " " +
            jobData?.created_by?.last_name,
          act: jobData?.act === 0 ? "-" : jobData?.act,
          gpa: jobData?.gpa === 0 ? "-" : jobData?.gpa,
          gmat: jobData?.gmat === 0 ? "-" : jobData?.gmat,
          sat: jobData?.sat === 0 ? "-" : jobData?.sat,
          email: jobData?.created_by?.email,
          job_title: jobData?.job_title,
          university: jobData?.education[0]?.name,
          partner_club: jobData?.education[0]?.partner_club,
          graduation_year: jobData?.education[0]?.graduation_year,
          resume: jobData?.resume,
         
          affinity: affinity,
          current_level: Current_level(),
          time: Time,
          random: rndInt,
          city: city1,
          company: company,
          industry: industry,
          title: title,
        });
      });
      setRow(data1);
      setSearchRow(data1);
      // console.log(data1)
    }
  }, [jobType, candidateList, OnClickGroupColumn]);

  const handleOnCellClick = (params: any) => {
    if (params.field === "resume") {
      dispatch(getResumeToken());
      setOpenModal(true);
      setResume(params.row.resume);
    }
  };
  const handleOnCellClickGroup = (params: any) => {
    if (
      params.rowNode.isAutoGenerated === false &&
      params.field === "first_name"
    ) {
      setIndex(params.row.index);
    }
  };

  const handlePaginationChange = React.useCallback(
    (event) => {
      // setSize(Number(event.target.value));
      // setJobId(event.target.value);
      setAnchorEl(null);
      setJobType(event.target.value);
      setJobId(event.target.value);
    },
    [candidateList, rows, OnClickGroupColumn]
  );
  const handleViewChange = React.useCallback(
    (event) => {
      // setSize(Number(event.target.value));
      setAnchorElView(null);
      let a = event.target.value;
      settableView(a);

      if (a === 1) {
        setRandomGroup(false);
        setCheckOpen(false);
      }

      if (a === 2) {
        setRandomGroup(true);

        setGroupColumn("university");
      }
    },
    [candidateList, rows, OnClickGroupColumn]
  );
  const handleGroupColumnChange = React.useCallback(
    (event) => {
      // setSize(Number(event.target.value));
      setGroupColumn(event.target.value);
    },
    [candidateList, rows, OnClickGroupColumn]
  );

  const handleSubGroupColumnChange = React.useCallback(
    (event) => {
      // setSize(Number(event.target.value));
      setSubGroupColumn(event.target.value);
    },
    [candidateList, rows, OnClickGroupColumn]
  );

  const handleapplyChange = () => {
    setCheckOpen(false);
    setRowGroupingModel([`${groupColumn}`, `${subgroupColumn}`]);
    if (tableView === 1) {
      setToggleOpen(false);
    }
    if (tableView === 2) {
      setToggleOpen(true);
    }
  };

  const bulkResumeHandler = () => {
    dispatch(bulkResumeDownload(jobId));
  };
  function onDocumentLoadSuccess(props: any) {
    setNumPages(props.numPages);
  }

  // Drop Down Button
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [anchorElView, setAnchorElView] = React.useState<null | HTMLElement>(
    null
  );
  const openView = Boolean(anchorElView);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  // const handleClose = (event: any) => {
  //   setAnchorEl(null);
  //   setJobType(event.currentTarget.dataset.myValue);
  //   setJobId(event.currentTarget.dataset.myValue)

  // };

  // view Button
  const handleClickView = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElView(event.currentTarget);
  };
  const handleCloseView = (event: any) => {
    setAnchorElView(null);
    // settableView(event.currentTarget.dataset.myValue);
    // setGroupColumn("university");
    // console.log(event.currentTarget.dataset.myValue)
  };

  // search

  const [searchText, setSearchText] = useState("");

  const SarchHandler = (e: any) => {
    setSearchText(e.target.value);
    const searchRegex = new RegExp(escapeRegExp(searchText), "i");
    const filteredRows = rows.filter((row: any) => {
      return Object.keys(row).some((field: any) => {
        return searchRegex.test(row[field]);
      });
    });

    setRow(filteredRows);
  };
  const clearSearch = () => {
    setSearchText("");
    setRow(searchRow);
  };
  useEffect(() => {
    setRow(rows);
    if (searchText === "") {
      setRow(searchRow);
    }
  }, [rows]);

  // search
  // Group
  const handleToggle = (value: any) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const CheckHandler = () => {
    setCheckOpen(true);
  };
  const ViewChangeHandler = (event: any) => {
    if (
      event.target.value === "JOB_VIEW" ||
      event.target.value === 2 ||
      event.target.value === "JOB_APPLY"
    ) {
      setViewType(event.target.value);
      setCustomLableId("");
    } else {
      setViewType("CUSTOM_LABLE");
      setCustomLableId(event.target.value);
    }
  };

  return (
    <div className="joblist-dashbard">
      <>
        <div
          style={{
            display: "flex",
            alignItems: "center",

            marginTop: "10px",
            marginBottom: "20px",
          }}
        >
          {/*  search bar */}
          <Box
            sx={{
              p: 0.5,
              pb: 0,
            }}
          >
            <TextField
              className="search"
              variant="standard"
              value={searchText}
              onChange={SarchHandler}
              placeholder="Search for candidate"
              InputProps={{
                disableUnderline: true,
                startAdornment: <SearchIcon style={{ color: "#999999" }} />,
                endAdornment: (
                  <IconButton
                    title="Clear"
                    aria-label="Clear"
                    size="small"
                    style={{
                      visibility: searchText ? "visible" : "hidden",
                    }}
                    onClick={clearSearch}
                  >
                    <CloseIcon />
                  </IconButton>
                ),
              }}
              sx={{
                width: {
                  xs: 1,
                  sm: "auto",
                },
                m: (theme) => theme.spacing(1, 0.5, 1.5),
                "& .MuiSvgIcon-root": {
                  mr: 0.5,
                },
                "& .MuiInput-underline:before": {
                  borderBottom: 1,
                  borderColor: "divider",
                },
              }}
            />
          </Box>

          <div>
            <FormControl sx={{ m: 1 }} className="formControal">
              <InputLabel id="demo-simple-select-autowidth-label">
                View
              </InputLabel>
              <Select
                defaultValue={1}
                onChange={handleViewChange}
                autoWidth
                label="View"
                className="select"
                MenuProps={{
                  PaperProps: { sx: { maxHeight: 300, width: 150 } },
                }}
              >
                <MenuItem value={1}>List view</MenuItem>
                <MenuItem value={2}>Group View</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="group">
            {randomGroup && (
              <FormControl sx={{ m: 1 }} className="formControal">
                <InputLabel id="demo-simple-select-autowidth-label">
                  Group By
                </InputLabel>
                <Select
                  onChange={handleGroupColumnChange}
                  autoWidth
                  defaultValue={"university"}
                  label="Group By"
                  className="select"
                >
                  {columns1.map((columns: any, index) => (
                    <MenuItem
                      value={columns.field !== "resume" && columns.field}
                    >
                      {columns.field !== "resume" && columns.headerName}
                    </MenuItem>
                  ))}
                  {/* <MenuItem value={1}>List view</MenuItem>
              <MenuItem value={2}>Group view</MenuItem> */}
                </Select>
              </FormControl>
            )}
            {randomGroup && (
              <FormControl sx={{ m: 1 }} className="formControal">
                <InputLabel id="demo-simple-select-autowidth-label">
                  Group By 2
                </InputLabel>
                <Select
                  onChange={handleSubGroupColumnChange}
                  autoWidth
                  label="Group By 2"
                  defaultValue={0}
                  className="select"
                >
                  <MenuItem value="0">None</MenuItem>
                  {columns1.map((columns: any, index) => (
                    <MenuItem
                      value={columns.field !== "resume" && columns.field}
                    >
                      {columns.field !== "resume" && columns.headerName}
                    </MenuItem>
                  ))}
                  {/* <MenuItem value={1}>List view</MenuItem>
              <MenuItem value={2}>Group view</MenuItem> */}
                </Select>
              </FormControl>
            )}
          </div>

          <button
            onClick={handleapplyChange}
            className="apply-btn"
            style={{
              border: "1px solid rgba(0,0,0,0.2)",
              color: "#121212",
              outline: "none",
              padding: "3px 20px",
              fontSize: "14px",
              fontWeight: "400",
              borderRadius: "6px",
              cursor: "pointer",

              display: "flex",
              alignItems: "center",
            }}
          >
            Apply <KeyboardArrowRightIcon fontSize="small" />
          </button>
        </div>

        <div className="job-button">
          <button onClick={() => setExpandTable(true)} className="expand">
            Expand
          </button>

          <div className="type-of-view">
            <p>View</p>
            <FormControl style={{ width: "160px" }}>
              <Select
                defaultValue={
                  viewType === "CUSTOM_LABLE" ? customLableId : viewType
                }
                onChange={ViewChangeHandler}
                className="select"
              >
                {jobDataMiscellances?.view?.visible && (
                  <MenuItem value="JOB_VIEW">
                    {jobDataMiscellances?.view?.label}
                  </MenuItem>
                )}
                {jobDataMiscellances?.click?.visible && (
                  <MenuItem value="JOB_APPLY">
                    {jobDataMiscellances?.click?.label}
                  </MenuItem>
                )}
                {jobDataMiscellances?.apply?.visible && (
                  <MenuItem value={2}>
                    {jobDataMiscellances?.apply?.label}
                  </MenuItem>
                )}
                {jobDataMiscellances?.customLables?.map(
                  (item: any, index: any) =>
                    !item?.hideVisualisation && (
                      <MenuItem value={`${item?.id}`}>
                        {item?.displayName}
                      </MenuItem>
                    )
                )}
              </Select>
            </FormControl>
          </div>
        </div>

        <div className="body">
          {!toggleOpen && (
            <StyledBox>
              <DataGridComponent
                columns={cols}
                getRowId={(row) => row._id}
                rows={rows}
                {...data}
                components={{
                  Toolbar: GridToolbar,
                }}
                loading={loading}
                checkboxSelection
                disableSelectionOnClick
                rowThreshold={0}
                onCellClick={handleOnCellClick}
                {...pagination}
              />
            </StyledBox>
          )}
          {toggleOpen && (
            <div
              style={{
                height: 600,
                width: "97%",
                marginRight: "30px",
                marginTop: "100px",
              }}
            >
              <DataGridPro
                getRowId={(row) => row._id}
                rows={rows}
                {...data}
                components={{
                  Toolbar: GridToolbar,
                }}
                apiRef={apiRef}
                columns={columns1}
                onCellClick={handleOnCellClickGroup}
                rowGroupingModel={rowGroupingModel}
                experimentalFeatures={{
                  rowGrouping: true,
                }}
              />
            </div>
          )}
        </div>
      </>

      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          setResume("");
        }}
      >
        <Box sx={modal}>
          <Document
            file={`${resume}&access_token=${resumeAccess?.access_token}`}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={console.error}
          >
            <Page pageNumber={pageNumber} />
          </Document>
        </Box>
      </Modal>

      <Dialog
        fullScreen
        open={expandTable}
        onClose={() => setExpandTable(false)}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <Button
              autoFocus
              color="inherit"
              onClick={() => setExpandTable(false)}
            >
              <CloseIcon />
            </Button>
          </Toolbar>
        </AppBar>
        <div
          className="joblist-dashbard"
          style={{ width: "98%", margin: "auto" }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              paddingLeft: "-5px",
              marginTop: "10px",
              marginBottom: "20px",
            }}
          >
            <Box
              sx={{
                p: 0.5,
                pb: 0,
              }}
            >
              <TextField
                className="search"
                variant="standard"
                value={searchText}
                onChange={SarchHandler}
                placeholder="Search inside the table data"
                InputProps={{
                  disableUnderline: true,
                  startAdornment: <SearchIcon style={{ color: "#999999" }} />,
                  endAdornment: (
                    <IconButton
                      title="Clear"
                      aria-label="Clear"
                      size="small"
                      style={{ visibility: searchText ? "visible" : "hidden" }}
                      onClick={clearSearch}
                    >
                      <CloseIcon />
                    </IconButton>
                  ),
                }}
                sx={{
                  width: {
                    xs: 1,
                    sm: "auto",
                  },
                  m: (theme) => theme.spacing(1, 0.5, 1.5),
                  "& .MuiSvgIcon-root": {
                    mr: 0.5,
                  },
                }}
              />
            </Box>

            <FormControl sx={{ m: 1 }} className="formControal">
              <InputLabel id="demo-simple-select-autowidth-label">
                View
              </InputLabel>
              <Select
                defaultValue={1}
                onChange={handleViewChange}
                autoWidth
                label="View"
                className="select"
                MenuProps={{
                  PaperProps: { sx: { maxHeight: 300, width: 150 } },
                }}
              >
                <MenuItem value={1}>List view</MenuItem>
                <MenuItem value={2}>Group View</MenuItem>
              </Select>
            </FormControl>

            <div className="group">
              {randomGroup && (
                <FormControl sx={{ m: 1 }} className="formControal">
                  <InputLabel id="demo-simple-select-autowidth-label">
                    Group By
                  </InputLabel>
                  <Select
                    onChange={handleGroupColumnChange}
                    autoWidth
                    defaultValue={0}
                    label="Group By"
                    className="select"
                  >
                    {columns1.map((columns: any, index) => (
                      <MenuItem
                        value={columns.field !== "resume" && columns.field}
                      >
                        {columns.field !== "resume" && columns.headerName}
                      </MenuItem>
                    ))}
                    {/* <MenuItem value={1}>List view</MenuItem>
              <MenuItem value={2}>Group view</MenuItem> */}
                  </Select>
                </FormControl>
              )}
              {randomGroup && (
                <FormControl sx={{ m: 1 }} className="formControal">
                  <InputLabel id="demo-simple-select-autowidth-label">
                    Group By 2
                  </InputLabel>
                  <Select
                    onChange={handleSubGroupColumnChange}
                    autoWidth
                    label="Group By 2"
                    defaultValue={0}
                    className="select"
                  >
                    <MenuItem value="0">None</MenuItem>
                    {columns1.map((columns: any, index) => (
                      <MenuItem
                        value={columns.field !== "resume" && columns.field}
                      >
                        {columns.field !== "resume" && columns.headerName}
                      </MenuItem>
                    ))}
                    {/* <MenuItem value={1}>List view</MenuItem>
              <MenuItem value={2}>Group view</MenuItem> */}
                  </Select>
                </FormControl>
              )}
            </div>

            <button
              onClick={handleapplyChange}
              className="apply-btn"
              style={{
                border: "1px solid rgba(0,0,0,0.2)",
                color: "#121212",
                outline: "none",
                padding: "3px 20px",
                fontSize: "14px",
                fontWeight: "400",
                borderRadius: "6px",
                cursor: "pointer",
                marginRight: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              Apply
            </button>

            <button
              onClick={() => setExpandTable(false)}
              className="apply-btn"
              style={{
                border: "1px solid rgba(0,0,0,0.2)",
                color: "#121212",
                outline: "none",
                padding: "3px 20px",
                fontSize: "14px",
                fontWeight: "400",
                borderRadius: "6px",
                cursor: "pointer",

                display: "flex",
                alignItems: "center",
              }}
            >
              Collapse
            </button>
          </div>

          <div className="body">
            {!toggleOpen && (
              <StyledBox style={{ width: "100%" }}>
                <DataGridComponent
                  columns={cols}
                  getRowId={(row) => row._id}
                  rows={rows}
                  {...data}
                  components={{
                    Toolbar: GridToolbar,
                  }}
                  loading={loading}
                  checkboxSelection
                  disableSelectionOnClick
                  rowThreshold={0}
                  onCellClick={handleOnCellClick}
                  {...pagination}
                />
              </StyledBox>
            )}
            {toggleOpen && (
              <div
                style={{
                  height: 600,
                  marginRight: "30px",
                  marginTop: "100px",
                }}
              >
                <DataGridPro
                  getRowId={(row) => row._id}
                  rows={rows}
                  {...data}
                  components={{
                    Toolbar: GridToolbar,
                  }}
                  apiRef={apiRef}
                  columns={columns1}
                  onCellClick={handleOnCellClickGroup}
                  rowGroupingModel={rowGroupingModel}
                  experimentalFeatures={{
                    rowGrouping: true,
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default JobUserTable;

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const modal = {
  display: "flex",
  justifyContent: "center",
  margin: "auto",
  backgroundColor: "white",
  width: "fit-content",
  height: "1240px",
  overflowY: "scroll",
  minWidth: "500px",
  maxWidth: "874px",
  maxHeight: "90vh",
  borderRadius: "10px",
  marginTop: "50px",
};
