import axios from "axios";
import {
  USER_SIGNIN_REQUEST,
  USER_SIGNIN_SUCCESS,
  USER_SIGNIN_FAIL,
  USER_LOGOUT,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  USER_FORGET_MAIL_REQUEST,
  USER_FORGET_MAIL_SUCCESS,
  USER_FORGET_MAIL_FAIL,
  USER_PASSWORD_CHANGE_REQUEST,
  USER_PASSWORD_CHANGE_SUCCESS,
  USER_PASSWORD_CHANGE_FAIL,
  USER_REFRESH_REQUEST,
  USER_REFRESH_SUCCESS,
  USER_REFRESH_FAIL,
} from "../Constants/userConstants";

const BASE = process.env.REACT_APP_BACKEND;

export const signIn = (user: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: USER_SIGNIN_REQUEST,
    });

    const { data } = await axios.post(`${BASE}/api/v1/auth/signin`, user);

    dispatch({
      type: USER_SIGNIN_SUCCESS,
      payload: data,
    });

    // Dispatch the success action
    localStorage.setItem("token", JSON.stringify(data));

    dispatch(userDetails());
  } catch (error: any) {
    dispatch({
      type: USER_SIGNIN_FAIL,
      payload: error.response,
    });
  }
};
export const signOut = () => async (dispatch: any) => {
  localStorage.removeItem("token");
  localStorage.removeItem("userInfo");

  dispatch({ type: USER_LOGOUT });
};

export const refreshToken = (action: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: USER_REFRESH_REQUEST,
    });

    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token")!)
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    };

    const { data } = await axios.get(`${BASE}/api/v1/auth/refresh`, config);

    // Dispatch the success action
    dispatch({
      type: USER_REFRESH_SUCCESS,
      payload: data,
    });

    localStorage.setItem("token", JSON.stringify(data));
    dispatch(action);
  } catch (error: any) {
    dispatch({
      type: USER_REFRESH_FAIL,
      payload: error,
    });
  }
};

export const userDetails = () => async (dispatch: any) => {
  try {
    dispatch({
      type: USER_DETAILS_REQUEST,
    });

    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token")!)
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    };

    const { data } = await axios.get(`${BASE}/api/v1/organisation/me`, config);

    // Dispatch the success action
    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    });

    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error: any) {
    dispatch({
      type: USER_DETAILS_FAIL,
      payload: error,
    });
  }
};

export const forgetMail = (email: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: USER_FORGET_MAIL_REQUEST,
    });

    const { data } = await axios.post(
      `${BASE}/api/v1/user/request/passwordreset`,
      {
        email: email,
      }
    );

    // Dispatch the success action
    dispatch({
      type: USER_FORGET_MAIL_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: USER_FORGET_MAIL_FAIL,
      payload: error.response,
    });
  }
};

export const userPasswordUpdate = (passData: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: USER_PASSWORD_CHANGE_REQUEST,
    });

    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token")!)
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    };

    const { data } = await axios.post(
      `${BASE}/api/v1/user/update/password`,
      passData,
      config
    );

    // Dispatch the success action
    dispatch({
      type: USER_PASSWORD_CHANGE_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: USER_PASSWORD_CHANGE_FAIL,
      payload: error.response,
    });
  }
};
