import React, { useState, useEffect } from "react";

import {
  DataGridPro,
  GridCellParams,
  GridToolbar,
  MuiEvent,
  GridRenderCellParams,
  GridApi,
  GridColumns,
  gridColumnVisibilityModelSelector,
  GridEvents,
  GridRowGroupingModel,
  useGridApiRef,
  GridRowHeightParams,
} from "@mui/x-data-grid-pro";
import {
  Chip,
  Modal,
  Stack,
  Box,
  Select,
  MenuItem,
  InputLabel,
  Button,
  FormControl,
  TextField,
  IconButton,
  Dialog,
  AppBar,
  Toolbar,
  Slide,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";

import { styled } from "@mui/material/styles";
import { FilterList } from "@mui/icons-material";

import { Document, Page, pdfjs } from "react-pdf";

import { useDispatch, useSelector } from "react-redux";
import {
  bulkResumeDownload,
  getResumeToken,
} from "../../redux/Actions/jobActions";
import { useNavigate } from "react-router-dom";

import moment from "moment-timezone";

import AffinityChips from "../UI/AffinityChips";

const BASE = process.env.REACT_APP_BACKEND;

const AntDesignStyledDataGridPro = styled(DataGridPro)(({ theme }) => ({
  border: `1px solid ${theme.palette.mode === "light" ? "#f0f0f0" : "#303030"}`,
  color:
    theme.palette.mode === "light"
      ? "rgba(0,0,0,.85)"
      : "rgba(255,255,255,0.85)",
  fontFamily: "Poppins",
  WebkitFontSmoothing: "auto",
  letterSpacing: "normal",
  "& .MuiDataGrid-columnsContainer": {
    backgroundColor: theme.palette.mode === "light" ? "#fafafa" : "#1d1d1d",
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    borderRight: `1px solid ${
      theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
    }`,
  },
  "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
    borderBottom: `1px solid ${
      theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
    }`,
  },
  "& .MuiDataGrid-cell": {
    color:
      theme.palette.mode === "light"
        ? "rgba(0,0,0,.85)"
        : "rgba(255,255,255,0.85)",
    fontFamily: "Poppins",
    WebkitFontSmoothing: "auto",
    letterSpacing: "normal",
    "& .MuiDataGrid-columnsContainer": {
      backgroundColor: theme.palette.mode === "light" ? "#fafafa" : "#1d1d1d",
    },
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-colCell, .MuiDataGrid-cell": {
      borderRight: `1px solid ${
        theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
      }`,
    },
    "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
      borderBottom: `1px solid ${
        theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
      }`,
    },
    "& .MuiDataGrid-cell": {
      color:
        theme.palette.mode === "light"
          ? "rgba(0,0,0,.85)"
          : "rgba(255,255,255,0.65)",
    },
    "& .MuiPaginationItem-root": {
      borderRadius: 0,
    },
    "& .MuiCheckbox-root svg": {
      width: 10,
      height: 16,
      backgroundColor: "transparent",
      border: `1px solid ${
        theme.palette.mode === "light" ? "#d9d9d9" : "rgb(67, 67, 67)"
      }`,
      borderRadius: 2,
    },
    "& .MuiCheckbox-root svg path": {
      display: "none",
    },
    "& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg": {
      backgroundColor: "#1890ff",
      borderColor: "#1890ff",
    },
    "& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after": {
      position: "absolute",
      display: "table",
      border: "2px solid #fff",
      borderTop: 0,
      borderLeft: 0,
      transform: "rotate(45deg) translate(-50%,-50%)",
      opacity: 1,
      transition: "all .2s cubic-bezier(.12,.4,.29,1.46) .1s",
      content: '""',
      top: "50%",
      left: "39%",
      width: 5.71428571,
      height: 9.14285714,
    },
    "& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after":
      {
        width: 8,
        height: 8,
        backgroundColor: "#1890ff",
        transform: "none",
        top: "39%",
        border: 0,
      },
  },
  "css-1fajery-MuiDataGrid-root .MuiDataGrid-cell--textLeft": {
    justifyContent: "center!important",
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: 600,
  width: "97%",

  marginRight: "30px",
  marginTop: "50px!important",
  "& .MuiFormGroup-options": {
    alignItems: "center",
    paddingBottom: theme.spacing(1),
    "& > div": {
      minWidth: 200,
      margin: theme.spacing(2),
    },
  },
}));

type GridDataType = "Employee" | "Commodity";
type GridDataThemeOption = "default" | "ant";
function escapeRegExp(value: string): string {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

interface GridPaginationSettings {
  pagination: boolean;
  autoPageSize: boolean;
  pageSize: number | undefined;
}

interface GridConfigOptions {
  size: number;
  type: GridDataType;
  pagesize: number;
  theme: GridDataThemeOption;
}

interface GridToolbarContainerProps {
  onApply: (options: GridConfigOptions) => void;
  size: number;
  type: GridDataType;
  theme: GridDataThemeOption;
}
const INITIAL_GROUPING_COLUMN_MODEL = ["university"];

const useKeepGroupingColumnsHidden = (
  apiRef: React.MutableRefObject<GridApi>,
  columns: GridColumns,
  initialModel: GridRowGroupingModel,
  leafField?: string
) => {
  const prevModel = React.useRef(initialModel);

  React.useEffect(() => {
    apiRef.current.subscribeEvent(
      GridEvents.rowGroupingModelChange,
      (newModel) => {
        // console.log("newModel", newModel);
        const columnVisibilityModel = {
          ...gridColumnVisibilityModelSelector(apiRef),
        };
        newModel.forEach((field) => {
          if (!prevModel.current.includes(field)) {
            columnVisibilityModel[field] = false;
          }
        });
        prevModel.current.forEach((field) => {
          if (!newModel.includes(field)) {
            columnVisibilityModel[field] = true;
          }
        });
        apiRef.current.setColumnVisibilityModel(columnVisibilityModel);
        prevModel.current = newModel;
      }
    );
  }, [apiRef]);

  return React.useMemo(
    () =>
      columns.map((colDef) =>
        initialModel.includes(colDef.field) ||
        (leafField && colDef.field === leafField)
          ? { ...colDef, hide: true }
          : colDef
      ),
    [columns, initialModel, leafField]
  );
};

let randomCol: any = [
  "primary",
  "secondary",
  "error",
  "info",
  "success",
  "warning",
];

const columns = [
  // { field: '_id', headerName: 'ID', width: 300 },
  {
    field: "application_status",
    headerName: "Status",
    width: 150,
  },
  {
    field: "first_name",
    headerName: "Candidate name",
    width: 140,
    // editable: true,
  },
  {
    field: "university",
    headerName: "University",

    width: 150,

    renderCell: (params: any) => (
      <Stack direction="row" spacing={1}>
        <Chip
          label={params.value}
          color={randomCol[params?.row?.random]}
          style={{
            backgroundColor: `${randomCol[params?.row?.random]}`,
            color: "#ffff",
            fontSize: "14px",
            height: "25px",
          }}
        />
      </Stack>
    ),
  },
  {
    field: "email",
    headerName: "Email",

    width: 200,
    // editable: true,
  },
  {
    field: "graduation_year",
    headerName: "Graduation Year",

    width: 200,
    // editable: true,
  },
  {
    field: "current_level",
    headerName: "Current Level",
    width: 130,
    // editable: true,
  },
  {
    field: "gpa",
    headerName: "GPA",

    width: 120,
    // editable: true,
  },
  {
    field: "sat",
    headerName: "SAT",

    width: 90,
    // editable: true,
  },
  {
    field: "act",
    headerName: "ACT",

    width: 90,
    // editable: true,
  },

  {
    field: "gmat",
    headerName: "GMAT",

    width: 90,
    // editable: true,
  },
  {
    field: "company",
    headerName: "Company",

    width: 200,
  },
  {
    field: "title",
    headerName: "Job Function",

    width: 200,
  },
  {
    field: "city",
    headerName: "City",

    width: 200,
  },
  // {
  //   field: "affinity_group",
  //   headerName: "Affinity Group",

  //   width: 290,
  //   renderCell: (params: any) => (
  //     <Stack direction="row" spacing={1}>
  //       {params?.row?.affinity_group !== "-"
  //         ? params?.row?.affinity_group?.map(
  //             (affinity: string, index: number) => (
  //               <>
  //                 <AffinityChips affinity={affinity} />
  //                 <br></br>
  //               </>
  //             )
  //           )
  //         : params?.row?.question1}
  //     </Stack>
  //   ),
  //   // editable: true,
  // },
  {
    field: "partner_club",
    headerName: "University Partner Clubs",

    width: 200,
    // editable: true,
  },
  {
    field: "resume",
    headerName: "Resume",
    width: 200,
    renderCell: (params: any) => (
      <Button style={{ padding: "4px 10px", fontSize: "11px" }}>
        View Resume
      </Button>
    ),
  },
  // {
  //   field: "industry",
  //   headerName: "Industry",

  //   width: 200,
  // },
];
const columns1 = [
  // { field: '_id', headerName: 'ID', width: 300 },
  {
    field: "application_status",
    headerName: "Status",
    width: 150,
  },
  {
    field: "first_name",
    headerName: "Candidate name",
    width: 140,
    // editable: true,
  },
  {
    field: "university",
    headerName: "University",

    width: 150,
  },
  {
    field: "email",
    headerName: "Email",

    width: 200,
    // editable: true,
  },
  {
    field: "graduation_year",
    headerName: "Graduation Year",

    width: 200,
    // editable: true,
  },
  {
    field: "current_level",
    headerName: "Current Level",
    width: 130,
    // editable: true,
  },
  {
    field: "gpa",
    headerName: "GPA",

    width: 120,
    // editable: true,
  },
  {
    field: "sat",
    headerName: "SAT",

    width: 90,
    // editable: true,
  },
  {
    field: "act",
    headerName: "ACT",

    width: 90,
    // editable: true,
  },

  {
    field: "gmat",
    headerName: "GMAT",

    width: 90,
    // editable: true,
  },
  {
    field: "company",
    headerName: "Company",

    width: 200,
  },
  {
    field: "title",
    headerName: "Job Function",

    width: 200,
  },
  {
    field: "city",
    headerName: "City",

    width: 200,
  },
  {
    field: "affinity",
    headerName: "Affinity Group",

    width: 290,

    // editable: true,
  },
  {
    field: "partner_club",
    headerName: "University Partner Clubs",

    width: 200,
    // editable: true,
  },
  // {
  //   field: "industry",
  //   headerName: "Industry",

  //   width: 200,
  // },
];

const CandidateList: React.FC<{
  candidateList: any;
  jobList: any;
  loading: any;
  setJobType: any;
  jobType: any;
  setIndex: any;
}> = ({ candidateList, jobList, loading, setJobType, jobType, setIndex }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const apiRef = useGridApiRef();

  const { resumeAccess, success } = useSelector(
    (state: any) => state.resumeToken
  );

  const [isAntDesign, setIsAntDesign] = React.useState<boolean>(false);
  const [type, setType] = React.useState<GridDataType>("Commodity");
  const [jobId, setJobId] = useState();
  const [OnClickGroupColumn, setOnClickGroupColumn] = useState("university");
  const [groupColumn, setGroupColumn] = useState<string>();
  const [randomGroup, setRandomGroup] = useState(false);
  const [tableView, settableView] = useState();
  const [rows, setRow] = useState([]);
  const [cols, setCols] = useState(columns);
  const [size, setSize] = useState(100);
  const [toggleOpen, setToggleOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [resume, setResume] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [subgroupColumn, setSubGroupColumn] = useState();
  const [searchRow, setSearchRow] = useState([]);
  const [checked, setChecked] = React.useState<any[]>([]);
  const [checkOpen, setCheckOpen] = useState(false);
  const [expandTable, setExpandTable] = useState(false);

  const [rowGroupingModel, setRowGroupingModel] = React.useState(
    INITIAL_GROUPING_COLUMN_MODEL
  );
  const windowSize = window.screen.availWidth;
  const token = localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token")!)
    : null;

  const data = {
    ...cols,
    ...rows,
  };
  // console.log("jobId", jobId);

  const [pagination, setPagination] = React.useState<GridPaginationSettings>({
    pagination: false,
    autoPageSize: false,
    pageSize: undefined,
  });

  const getActiveTheme = () => {
    return isAntDesign ? "ant" : "default";
  };

  const handleApplyClick = (settings: any) => {
    if (size !== settings.size) {
      setSize(settings.size);
    }

    if (type !== settings.type) {
      setType(settings.type);
    }

    if (getActiveTheme() !== settings.theme) {
      setIsAntDesign(!isAntDesign);
    }

    const newPaginationSettings: GridPaginationSettings = {
      pagination: settings.pagesize !== -1,
      autoPageSize: settings.pagesize === 0,
      pageSize: settings.pagesize > 0 ? settings.pagesize : undefined,
    };

    setPagination(
      (
        currentPaginationSettings: GridPaginationSettings
      ): GridPaginationSettings => {
        if (
          currentPaginationSettings.pagination ===
            newPaginationSettings.pagination &&
          currentPaginationSettings.autoPageSize ===
            newPaginationSettings.autoPageSize &&
          currentPaginationSettings.pageSize === newPaginationSettings.pageSize
        ) {
          return currentPaginationSettings;
        }
        return newPaginationSettings;
      }
    );
  };

  const DataGridComponent = isAntDesign
    ? AntDesignStyledDataGridPro
    : DataGridPro;

  useEffect(() => {
    if (
      candidateList?.records[0]?.job_questions !== null &&
      candidateList?.records[0]?.job_questions?.length !== 0
    ) {
      let tempCols = [...columns];
      candidateList?.records[0]?.job_questions?.map((que: any) => {
        if (tempCols.find((col: any) => col.field !== que.name)) {
          if (que.key === "affinity_groups") {
            tempCols.splice(11, 0, {
              field: que.name,
              headerName: que.name,
              width: 300,
              renderCell: (params: any) => (
                <Stack direction="row" spacing={1}>
                  {params?.row?.[que.name] !== "-"
                    ? params?.row?.[que.name].map(
                        (affinity: string, index: number) => (
                          <>
                            <AffinityChips affinity={affinity} />
                            <br></br>
                          </>
                        )
                      )
                    : params?.row?.question1}
                </Stack>
              ),
            });
          }
        }
      });

      setCols(tempCols);
    }
    if (
      candidateList?.records[0]?.job_score_parameters !== null &&
      candidateList?.records[0]?.job_score_parameters?.length !== 0
    ) {
      let tempCols = [...columns];
      candidateList?.records[0]?.job_score_parameters?.map((que: any) => {
        if (tempCols.find((col: any) => col.field !== que.name)) {
          tempCols.push({
            field: que.name,
            headerName: que.name.charAt(0).toUpperCase() + que.name.slice(1),
            width: 150,
          });
        }
      });

      tempCols.push({
        field: "total_score",
        headerName: "Total Score",
        width: 150,
      });

      tempCols.push();

      setCols(tempCols);
    }
  }, [candidateList]);

  useEffect(() => {
    if (!loading) {
      const formattedData = candidateList?.records.map(
        (candidate: any, index: any) => {
          const {
            _id,
            act,
            gpa,
            gmat,
            sat,
            created_by,
            job_title,
            education,
            job_questions,
            job_score_parameters,
            resume,
            timestamp,
            work_experiences,
            application_status,
          } = candidate;

          const Current_level = () => {
            const month = new Date().getMonth();
            const year = new Date().getFullYear();
            if (
              education?.[0]?.graduation_year < year ||
              (education?.[0]?.graduation_year === year && month > 7)
            ) {
              return education?.[0]?.education_type === 0
                ? "Professional Undergraduate"
                : "Professional MBA";
            } else {
              return education?.[0]?.education_type === 0
                ? "Undergraduate"
                : "MBA";
            }
          };

          const Time = moment(timestamp).format("LT");
          const rndInt = Math.floor(Math.random() * 5) + 1;

          const city1 = work_experiences?.[0]?.city || "-";
          const company = work_experiences?.[0]?.company || "-";
          const industry = work_experiences?.[0]?.industry || "-";
          const title = work_experiences?.[0]?.title || "-";

          const affinity =
            job_questions?.find((aff: any) => aff.key === "affinity_groups")
              ?.values || [];

          let newQues = job_questions?.reduce((ques: any, curr: any) => {
            ques[curr.name] = curr.values?.length ? curr.values.join(",") : "-";
            return ques;
          }, {});

          let total = 0;
          if (job_score_parameters) {
            total = job_score_parameters.reduce(
              (sum: any, score: any) =>
                sum + (score.value || 0) * (score.weight || 0),
              0
            );
          }

          const applicationStatusOptions = [
            "Not Yet Reviewed",
            "Decline",
            "Maybe",
            "Invite for Interview",
            "Offer",
            "Offer Accepted",
          ];
          const status = applicationStatusOptions[application_status || 0];

          return {
            _id,
            index,
            first_name: `${created_by?.first_name} ${created_by?.last_name}`,
            act: act || "-",
            gpa: gpa || "-",
            gmat: gmat || "-",
            sat: sat || "-",
            email: created_by?.email,
            job_title,
            university: education?.[0]?.name || "-",
            partner_club: education?.[0]?.partner_club || "-",
            affinity: affinity.join(" ").replace(/,/g, " ").split(),
            graduation_year: education?.[0]?.graduation_year || "-",
            resume,
            current_level: Current_level(),
            time: Time,
            random: rndInt,
            city: city1,
            company,
            industry,
            title,
            ...newQues,
            total_score: Math.round(total * 100) / 100,
            application_status: status,
          };
        }
      );

      setRow(formattedData || []);
      setSearchRow(formattedData || []);
    }
  }, [loading, candidateList, OnClickGroupColumn]);

  const handleOnCellClick = (params: any) => {
    if (params.field === "first_name") {
      navigate(`?view=details&applicationId=${params.id}`);
      setIndex(params.row.index);
      // console.log(params.row.index)
    }
    if (params.field === "resume") {
      dispatch(getResumeToken());
      setOpenModal(true);
      setResume(params.row.resume);
    }
  };
  const handleOnCellClickGroup = (params: any) => {
    if (
      params.rowNode.isAutoGenerated === false &&
      params.field === "first_name"
    ) {
      setIndex(params.row.index);
    }
  };

  const handlePaginationChange = React.useCallback(
    (event) => {
      // setSize(Number(event.target.value));
      // setJobId(event.target.value);
      setAnchorEl(null);
      setJobType(event.target.value);
      setJobId(event.target.value);
    },
    [candidateList, rows, OnClickGroupColumn]
  );
  const handleViewChange = React.useCallback(
    (event) => {
      // setSize(Number(event.target.value));
      setAnchorElView(null);
      let a = event.target.value;
      settableView(a);

      if (a === 1) {
        setRandomGroup(false);
        setCheckOpen(false);
      }

      if (a === 2) {
        setRandomGroup(true);

        setGroupColumn("university");
      }
    },
    [candidateList, rows, OnClickGroupColumn]
  );
  const handleGroupColumnChange = React.useCallback(
    (event) => {
      // setSize(Number(event.target.value));
      setGroupColumn(event.target.value);
    },
    [candidateList, rows, OnClickGroupColumn]
  );

  const handleSubGroupColumnChange = React.useCallback(
    (event) => {
      // setSize(Number(event.target.value));
      setSubGroupColumn(event.target.value);
    },
    [candidateList, rows, OnClickGroupColumn]
  );

  const handleapplyChange = () => {
    setCheckOpen(false);
    setRowGroupingModel([`${groupColumn}`, `${subgroupColumn}`]);
    if (tableView === 1) {
      setToggleOpen(false);
    }
    if (tableView === 2) {
      setToggleOpen(true);
    }
  };

  const bulkResumeHandler = () => {
    dispatch(bulkResumeDownload(jobId));
  };
  function onDocumentLoadSuccess(props: any) {
    setNumPages(props.numPages);
  }

  // Drop Down Button
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [anchorElView, setAnchorElView] = React.useState<null | HTMLElement>(
    null
  );
  const openView = Boolean(anchorElView);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  // const handleClose = (event: any) => {
  //   setAnchorEl(null);
  //   setJobType(event.currentTarget.dataset.myValue);
  //   setJobId(event.currentTarget.dataset.myValue)

  // };

  // view Button
  const handleClickView = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElView(event.currentTarget);
  };
  const handleCloseView = (event: any) => {
    setAnchorElView(null);
    // settableView(event.currentTarget.dataset.myValue);
    // setGroupColumn("university");
    // console.log(event.currentTarget.dataset.myValue)
  };

  // search

  const [searchText, setSearchText] = useState("");

  const SarchHandler = (e: any) => {
    setSearchText(e.target.value);
    const searchRegex = new RegExp(escapeRegExp(searchText), "i");
    const filteredRows = rows.filter((row: any) => {
      return Object.keys(row).some((field: any) => {
        return searchRegex.test(row[field].toString());
      });
    });
    setRow(filteredRows);
  };
  const clearSearch = () => {
    setSearchText("");
    setRow(searchRow);
  };
  useEffect(() => {
    setRow(rows);
    if (searchText === "") {
      setRow(searchRow);
    }
  }, [rows]);

  // search
  // Group
  const handleToggle = (value: any) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const CheckHandler = () => {
    setCheckOpen(true);
  };

  return (
    <div className="joblist">
      <div className="heading-button">
        <h1 className="head"> Candidate list</h1>
        <FormControl sx={{ m: 1, minWidth: 300, maxWidth: 400 }}>
          <InputLabel id="demo-simple-select-autowidth-label">Job</InputLabel>
          <Select
            defaultValue="0"
            onChange={handlePaginationChange}
            autoWidth
            label="Job"
            style={{
              width: "400px",
              overflow: "hidden",
              height: "45px",
              color: "#7f00bb",
              background: "rgba(127, 0, 187, 0.08)",
              fontWeight: "500",
            }}
            MenuProps={{
              PaperProps: { sx: { maxHeight: 500, maxWidth: 400 } },
            }}
          >
            <MenuItem value="0" disabled>
              Select Job
            </MenuItem>
            {jobList?.records?.map((application: any) => (
              <MenuItem value={application._id}>
                {application.job_title + " - " + application._id}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      {jobType !== "" ? (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",

              marginTop: "10px",
              marginBottom: "20px",
            }}
          >
            {/*  search bar */}
            <Box
              sx={{
                p: 0.5,
                pb: 0,
              }}
            >
              <TextField
                className="search"
                variant="standard"
                value={searchText}
                onChange={SarchHandler}
                placeholder="Search for candidate"
                InputProps={{
                  disableUnderline: true,
                  startAdornment: <SearchIcon style={{ color: "#999999" }} />,
                  endAdornment: (
                    <IconButton
                      title="Clear"
                      aria-label="Clear"
                      size="small"
                      style={{
                        visibility: searchText ? "visible" : "hidden",
                      }}
                      onClick={clearSearch}
                    >
                      <CloseIcon />
                    </IconButton>
                  ),
                }}
                sx={{
                  width: {
                    xs: 1,
                    sm: "auto",
                  },
                  m: (theme) => theme.spacing(1, 0.5, 1.5),
                  "& .MuiSvgIcon-root": {
                    mr: 0.5,
                  },
                  "& .MuiInput-underline:before": {
                    borderBottom: 1,
                    borderColor: "divider",
                  },
                }}
              />
            </Box>

            <div>
              <FormControl sx={{ m: 1 }} className="formControal">
                <InputLabel id="demo-simple-select-autowidth-label">
                  View
                </InputLabel>
                <Select
                  defaultValue={1}
                  onChange={handleViewChange}
                  autoWidth
                  label="View"
                  className="select"
                  MenuProps={{
                    PaperProps: { sx: { maxHeight: 300, width: 150 } },
                  }}
                >
                  <MenuItem value={1}>List view</MenuItem>
                  <MenuItem value={2}>Group View</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="group">
              {randomGroup && (
                <FormControl sx={{ m: 1 }} className="formControal">
                  <InputLabel id="demo-simple-select-autowidth-label">
                    Group By
                  </InputLabel>
                  <Select
                    onChange={handleGroupColumnChange}
                    autoWidth
                    defaultValue={"university"}
                    label="Group By"
                    className="select"
                  >
                    {columns1.map((columns: any, index) => (
                      <MenuItem
                        value={columns.field !== "resume" && columns.field}
                      >
                        {columns.field !== "resume" && columns.headerName}
                      </MenuItem>
                    ))}
                    {/* <MenuItem value={1}>List view</MenuItem>
              <MenuItem value={2}>Group view</MenuItem> */}
                  </Select>
                </FormControl>
              )}
              {randomGroup && (
                <FormControl sx={{ m: 1 }} className="formControal">
                  <InputLabel id="demo-simple-select-autowidth-label">
                    Group By 2
                  </InputLabel>
                  <Select
                    onChange={handleSubGroupColumnChange}
                    autoWidth
                    label="Group By 2"
                    defaultValue={0}
                    className="select"
                  >
                    <MenuItem value="0">None</MenuItem>
                    {columns1.map((columns: any, index) => (
                      <MenuItem
                        value={columns.field !== "resume" && columns.field}
                      >
                        {columns.field !== "resume" && columns.headerName}
                      </MenuItem>
                    ))}
                    {/* <MenuItem value={1}>List view</MenuItem>
              <MenuItem value={2}>Group view</MenuItem> */}
                  </Select>
                </FormControl>
              )}
            </div>

            <button
              onClick={handleapplyChange}
              className="apply-btn"
              style={{
                background: "#7f00bb",
                color: "white",
                outline: "none",

                padding: "3px 15px",
                border: "none",
                borderRadius: "10px",
                cursor: "pointer",

                display: "flex",
                alignItems: "center",
              }}
            >
              Apply <KeyboardArrowRightIcon fontSize="small" />
            </button>

            <button
              onClick={() => setExpandTable(true)}
              style={{
                background: "#7f00bb",
                color: "white",
                outline: "none",
                fontSize: "20px",
                height: "40px",
                padding: "3px 30px",
                border: "none",
                borderRadius: "10px",
                cursor: "pointer",
                marginLeft: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              Expand
            </button>
          </div>

          <div className="job-button">
            {jobType && (
              <a
                href={`${BASE}/api/v1/jobapplication/job/download/${jobId}?access_token=${token.access_token}`}
                target="_blank"
              >
                <button
                  style={{
                    background: "rgba(127, 0, 187, 0.08)",
                    color: "#7f00bb",
                    outline: "none",
                    fontSize: "16px",

                    padding: "3px 15px",
                    border: "none",
                    borderRadius: "20px",
                    cursor: "pointer",
                    fontWeight: "500",
                    display: "flex",
                    alignItems: "center",
                    textDecoration: "none",
                  }}
                  onClick={bulkResumeHandler}
                >
                  Download All Resumes
                  <FilterList />
                </button>
              </a>
            )}
          </div>

          <div className="body">
            {!toggleOpen && (
              <StyledBox>
                <DataGridComponent
                  columns={cols}
                  getRowId={(row) => row._id}
                  rows={rows}
                  {...data}
                  components={{
                    Toolbar: GridToolbar,
                  }}
                  loading={loading}
                  checkboxSelection
                  disableSelectionOnClick
                  rowThreshold={0}
                  onCellClick={handleOnCellClick}
                  {...pagination}
                />
              </StyledBox>
            )}
            {toggleOpen && (
              <div
                style={{
                  height: 600,
                  width: "97%",
                  marginRight: "30px",
                  marginTop: "100px",
                }}
              >
                <DataGridPro
                  getRowId={(row) => row._id}
                  rows={rows}
                  {...data}
                  components={{
                    Toolbar: GridToolbar,
                  }}
                  apiRef={apiRef}
                  columns={columns1}
                  onCellClick={handleOnCellClickGroup}
                  rowGroupingModel={rowGroupingModel}
                  experimentalFeatures={{
                    rowGrouping: true,
                  }}
                />
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="default-text">
          <div>
            <img
              src="https://s1.ecnstatic.com/ecn/images/common/laptop.png"
              alt=""
            />
            <p>Please Select an Job</p>
          </div>
        </div>
      )}
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          setResume("");
        }}
      >
        <Box sx={modal}>
          <Document
            file={`${resume}&access_token=${resumeAccess?.access_token}`}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={console.error}
          >
            <Page pageNumber={pageNumber} />
          </Document>
        </Box>
      </Modal>

      <Dialog
        fullScreen
        open={expandTable}
        onClose={() => setExpandTable(false)}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <Button
              autoFocus
              color="inherit"
              onClick={() => setExpandTable(false)}
            >
              <CloseIcon />
            </Button>
          </Toolbar>
        </AppBar>
        <div className="joblist" style={{ width: "98%", margin: "auto" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              paddingLeft: "-5px",
              marginTop: "10px",
              marginBottom: "20px",
            }}
          >
            <Box
              sx={{
                p: 0.5,
                pb: 0,
              }}
            >
              <TextField
                className="search"
                variant="standard"
                value={searchText}
                onChange={SarchHandler}
                placeholder="Search inside the table data"
                InputProps={{
                  disableUnderline: true,
                  startAdornment: <SearchIcon style={{ color: "#999999" }} />,
                  endAdornment: (
                    <IconButton
                      title="Clear"
                      aria-label="Clear"
                      size="small"
                      style={{ visibility: searchText ? "visible" : "hidden" }}
                      onClick={clearSearch}
                    >
                      <CloseIcon />
                    </IconButton>
                  ),
                }}
                sx={{
                  width: {
                    xs: 1,
                    sm: "auto",
                  },
                  m: (theme) => theme.spacing(1, 0.5, 1.5),
                  "& .MuiSvgIcon-root": {
                    mr: 0.5,
                  },
                }}
              />
            </Box>

            <FormControl sx={{ m: 1 }} className="formControal">
              <InputLabel id="demo-simple-select-autowidth-label">
                View
              </InputLabel>
              <Select
                defaultValue={1}
                onChange={handleViewChange}
                autoWidth
                label="View"
                className="select"
                MenuProps={{
                  PaperProps: { sx: { maxHeight: 300, width: 150 } },
                }}
              >
                <MenuItem value={1}>List view</MenuItem>
                <MenuItem value={2}>Group View</MenuItem>
              </Select>
            </FormControl>

            <div className="group">
              {randomGroup && (
                <FormControl sx={{ m: 1 }} className="formControal">
                  <InputLabel id="demo-simple-select-autowidth-label">
                    Group By
                  </InputLabel>
                  <Select
                    onChange={handleGroupColumnChange}
                    autoWidth
                    defaultValue={0}
                    label="Group By"
                    className="select"
                  >
                    {columns1.map((columns: any, index) => (
                      <MenuItem
                        value={columns.field !== "resume" && columns.field}
                      >
                        {columns.field !== "resume" && columns.headerName}
                      </MenuItem>
                    ))}
                    {/* <MenuItem value={1}>List view</MenuItem>
              <MenuItem value={2}>Group view</MenuItem> */}
                  </Select>
                </FormControl>
              )}
              {randomGroup && (
                <FormControl sx={{ m: 1 }} className="formControal">
                  <InputLabel id="demo-simple-select-autowidth-label">
                    Group By 2
                  </InputLabel>
                  <Select
                    onChange={handleSubGroupColumnChange}
                    autoWidth
                    label="Group By 2"
                    defaultValue={0}
                    className="select"
                  >
                    <MenuItem value="0">None</MenuItem>
                    {columns1.map((columns: any, index) => (
                      <MenuItem
                        value={columns.field !== "resume" && columns.field}
                      >
                        {columns.field !== "resume" && columns.headerName}
                      </MenuItem>
                    ))}
                    {/* <MenuItem value={1}>List view</MenuItem>
              <MenuItem value={2}>Group view</MenuItem> */}
                  </Select>
                </FormControl>
              )}
            </div>

            <button
              onClick={handleapplyChange}
              style={{
                background: "#7f00bb",
                color: "white",
                outline: "none",
                fontSize: "20px",
                height: "40px",
                padding: "3px 30px",
                border: "none",
                borderRadius: "10px",
                cursor: "pointer",

                display: "flex",
                alignItems: "center",
              }}
            >
              Apply
            </button>

            <button
              onClick={() => setExpandTable(false)}
              style={{
                background: "#7f00bb",
                color: "white",
                outline: "none",
                fontSize: "20px",
                height: "40px",
                padding: "3px 30px",
                border: "none",
                borderRadius: "10px",
                cursor: "pointer",
                marginLeft: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              Collapse
            </button>
          </div>

          <div className="body">
            {!toggleOpen && (
              <StyledBox style={{ width: "100%" }}>
                <DataGridComponent
                  columns={cols}
                  getRowId={(row) => row._id}
                  rows={rows}
                  {...data}
                  components={{
                    Toolbar: GridToolbar,
                  }}
                  loading={loading}
                  checkboxSelection
                  disableSelectionOnClick
                  rowThreshold={0}
                  onCellClick={handleOnCellClick}
                  {...pagination}
                />
              </StyledBox>
            )}
            {toggleOpen && (
              <div
                style={{
                  height: 600,
                  marginRight: "30px",
                  marginTop: "100px",
                }}
              >
                <DataGridPro
                  getRowId={(row) => row._id}
                  rows={rows}
                  {...data}
                  components={{
                    Toolbar: GridToolbar,
                  }}
                  apiRef={apiRef}
                  columns={columns1}
                  onCellClick={handleOnCellClickGroup}
                  rowGroupingModel={rowGroupingModel}
                  experimentalFeatures={{
                    rowGrouping: true,
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default CandidateList;

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const modal = {
  display: "flex",
  justifyContent: "center",
  margin: "auto",
  backgroundColor: "white",
  width: "fit-content",
  height: "1240px",
  overflowY: "scroll",
  minWidth: "500px",
  maxWidth: "874px",
  maxHeight: "90vh",
  borderRadius: "10px",
  marginTop: "50px",
};
