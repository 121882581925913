import {
  Button,
  FormControl,
  MenuItem,
  Pagination,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { jobAnalytics } from "../../../redux/Actions/JobAnalyticAction";

import CandidatesList from "./CandidatesList";
import PieChart from "../../EventDashboard/PieChart";
import { ResponsiveBar } from "@nivo/bar";
import { getEventSummary } from "../../../redux/Actions/eventActions";
import { useSearchParams } from "react-router-dom";

const BodySection: React.FC<{
  id: any;
  timeFilter: any;
  DataType: any;
  setTypeFilter: any;
  typeFilter: any;
}> = ({ id, timeFilter, DataType, setTypeFilter, typeFilter }) => {
  const dispatch = useDispatch();
  let mapData: any = [];
  const key1: any = [];
  const key: any = [];
  const data: any = [];
  const [searchParams] = useSearchParams();
  let viewtab = searchParams.get("view");
  let tabId = searchParams.get("id");

  const { jobData, loading } = useSelector(
    (state: any) => state.jobDataAnalytics
  );

  const { jobDataMiscellances, error } = useSelector(
    (state: any) => state.jobDataAnalyticsMiscellaneous
  );

  const size = window.screen.availWidth;

  const [view, setView] = useState<any>(0);
  const [selectNumber, setSelectNumber] = useState(20);
  const [viewName, setViewName] = useState("university");
  const [customLableId, setCustomLableId] = useState<any>("");
  const [chooseGraph, setChooseGraph] = useState(2);
  const [hideViewBy, sethideViewBy] = useState(false);
  const [viewData, setViewData] = useState<any>();
  const [pg, setPg] = useState(1);

  useEffect(() => {
    var d = new Date();
    var ts = Math.floor(Date.now() / 1000);
    dispatch(
      jobAnalytics({
        id: customLableId != "" ? "" : id,
        startTime: timeFilter === 9999999999 ? 0 : timeFilter,
        endTime: timeFilter === 9999999999 ? 9999999999 : ts,
        groupBy: viewName,
        type: typeFilter,
        customLableId: customLableId,
        analyticsType: DataType,
      })
    );
  }, [id, timeFilter, view, viewName, typeFilter, customLableId, DataType]);

  useEffect(() => {
    setViewData(jobData);
  }, [id, jobData, chooseGraph]);

  useEffect(() => {
    if (viewtab === "jobs") {
      if (jobDataMiscellances?.apply?.visible) {
        setTypeFilter("JOB_APPLY");
        setCustomLableId("");
      } else if (jobDataMiscellances?.click?.visible) {
        setTypeFilter("JOB_APPLICATION");
        setCustomLableId("");
      } else if (jobDataMiscellances?.view?.visible) {
        setTypeFilter("JOB_VIEW");
        setCustomLableId("");
      } else {
        let id = jobDataMiscellances?.customLables
        ? (jobDataMiscellances.customLables.length === 0
            ? ""
            : jobDataMiscellances.customLables[0]?.id)
        : undefined;
      
        setTypeFilter("CUSTOM_LABLE");
        setCustomLableId(id);
      }
    }
    if (view === "events") {
      if (jobDataMiscellances?.apply?.visible) {
        setTypeFilter("EVENT_APPLY");
        setCustomLableId("");
      } else if (jobDataMiscellances?.click?.visible) {
        setTypeFilter("EVENT_REGISTRATION");
        console.log("fgcvdgcvsdcsg");
        setCustomLableId("");
      } else if (jobDataMiscellances?.view?.visible) {
        setTypeFilter("EVENT_VIEW");
        setCustomLableId("");
      } else {
        let id = jobDataMiscellances?.customLables
          ? jobDataMiscellances.customLables.length === 0
            ? ""
            : jobDataMiscellances.customLables[0]?.id
          : undefined;

        setTypeFilter("CUSTOM_LABLE");
        setCustomLableId(id);
      }
    }
  }, [jobDataMiscellances, view, id]);

  useEffect(() => {
    if (id) {
      setView(0);
    }
  }, [id]);

  viewData?.records?.map((university: any) => key.push(university?._id));
  let total_University = key.length;
  function pushToAry(name: any, val: any, index: any) {
    var obj: any = {};
    obj["id"] = index;
    obj[`${name}`] = val;
    obj["value"] = val;
    obj["University"] = name;
    data.push(obj);
  }

  viewData?.records?.map((university: any, index: any) =>
    pushToAry(university._id, university.count, index)
  );

  let sorted_data = data.sort((a: any, b: any) => {
    return b.value - a.value;
  });

  sorted_data
    .slice((pg - 1) * selectNumber, pg * selectNumber)
    .map((university: any) => key1.push(university.University));
  const total_key = key1.length;

  function mapAry(name: any, val: any, index: any) {
    var obj: any = {};
    obj["id"] = index;
    obj[`${name}`] = val;
    obj["value"] = val;
    obj["University"] = name;
    obj[`${name}Color`] = "hsl(5, 70%, 50%)";
    mapData.push(obj);
  }

  sorted_data
    ?.slice((pg - 1) * selectNumber, pg * selectNumber)
    .map((university: any, index: any) =>
      mapAry(university.University, university.value, index)
    );

  const handleViewChange = (event: any) => {
    setViewName(event.target.value);
  };

  const handleTypeChange = (event: any) => {
    setChooseGraph(event.target.value);
    if (event.target.value === 2) {
      sethideViewBy(false);
    }
    if (event.target.value === 1) {
      sethideViewBy(true);
    }
  };
  const handleTopChoose = (event: any) => {
    setSelectNumber(event.target.value);
    setPg(1);
  };
  const TypeChangeHandler = (event: any) => {
    if (
      event.target.value === "JOB_VIEW" ||
      event.target.value === "JOB_APPLICATION" ||
      event.target.value === "JOB_APPLY"
    ) {
      setTypeFilter(event.target.value);
      setCustomLableId("");
    } else {
      setTypeFilter("CUSTOM_LABLE");
      setCustomLableId(event.target.value);
    }
  };

  const EventTypeChangeHandler = (event: any) => {
    if (
      event.target.value === "EVENT_VIEW" ||
      event.target.value === "EVENT_REGISTRATION" ||
      event.target.value === "EVENT_APPLY"
    ) {
      setTypeFilter(event.target.value);
      setCustomLableId("");
    } else {
      setTypeFilter("CUSTOM_LABLE");
      setCustomLableId(event.target.value);
    }
  };
  const pageHandler = (e: any, page: any) => {
    setPg(page);
  };

  console.log(typeFilter);
  return (
    <div className="dashboard-body">
      <div
        className={
          view === 1 ? "graph-view button-group-postion" : "graph-view "
        }
      >
        {view === 0 && (
          <div className="graph-type">
            {viewtab === "jobs" ? (
              <div className="select-graph">
                <p>Interaction</p>
                <FormControl style={{ width: "160px" }}>
                  <Select
                    value={
                      typeFilter === "CUSTOM_LABLE" ? customLableId : typeFilter
                    }
                    onChange={TypeChangeHandler}
                    style={{ height: "40px" }}
                  >
                    {jobDataMiscellances?.view?.visible && (
                      <MenuItem value="JOB_VIEW">
                        {jobDataMiscellances?.view?.label}
                      </MenuItem>
                    )}

                    {jobDataMiscellances?.click?.visible && (
                      <MenuItem value="JOB_APPLICATION">
                        {jobDataMiscellances?.click?.label}
                      </MenuItem>
                    )}

                    {jobDataMiscellances?.apply?.visible && (
                      <MenuItem value="JOB_APPLY">
                        {jobDataMiscellances?.apply?.label}
                      </MenuItem>
                    )}
                    {jobDataMiscellances?.customLables?.map(
                      (item: any, index: any) => (
                        <MenuItem value={`${item?.id}`}>
                          {item?.displayName}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </div>
            ) : (
              <div className="select-graph">
                <p>Interaction</p>
                <FormControl style={{ width: "160px" }}>
                  <Select
                    value={
                      typeFilter === "CUSTOM_LABLE" ? customLableId : typeFilter
                    }
                    onChange={EventTypeChangeHandler}
                    style={{ height: "40px" }}
                  >
                    {jobDataMiscellances?.view?.visible && (
                      <MenuItem value="EVENT_VIEW">
                        {jobDataMiscellances?.view?.label}
                      </MenuItem>
                    )}
                    {jobDataMiscellances?.click?.visible && (
                      <MenuItem value="EVENT_REGISTRATION">
                        {jobDataMiscellances?.click?.label}
                      </MenuItem>
                    )}
                    {jobDataMiscellances?.apply?.visible && (
                      <MenuItem value="EVENT_APPLY">
                        {jobDataMiscellances?.apply?.label}
                      </MenuItem>
                    )}
                    {jobDataMiscellances?.customLables?.map(
                      (item: any, index: any) =>
                        !item?.hideVisualisation && (
                          <MenuItem value={`${item?.id}`}>
                            {item?.displayName}
                          </MenuItem>
                        )
                    )}
                  </Select>
                </FormControl>
              </div>
            )}

            <div className="select-graph">
              <p>Data</p>
              <FormControl style={{ width: "160px" }}>
                <Select
                  value={viewName}
                  onChange={handleViewChange}
                  style={{ height: "40px" }}
                >
                  <MenuItem value={"job_function"}>Job Function</MenuItem>
                  <MenuItem value={"university"}>University</MenuItem>

                  {tabId != "63fb520df3ba0a790b4a5461" && (
                    <MenuItem value={"gpa"}>GPA</MenuItem>
                  )}
                  {tabId != "63fb520df3ba0a790b4a5461" && (
                    <MenuItem value={"graduation_year"}>
                      Graduation Year
                    </MenuItem>
                  )}

                  <MenuItem value={"diversity"}>Diversity</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="select-graph">
              <p> Graph type</p>
              <FormControl style={{ width: "160px" }}>
                <Select
                  value={chooseGraph}
                  onChange={handleTypeChange}
                  style={{ height: "40px" }}
                >
                  <MenuItem value={1}>Bar Graph</MenuItem>
                  <MenuItem value={2}>Pie Chart</MenuItem>
                </Select>
              </FormControl>
            </div>

            {hideViewBy && (
              <div className="select-graph">
                <p>View By</p>
                <FormControl style={{ width: "70px" }}>
                  <Select
                    defaultValue={selectNumber}
                    autoWidth
                    style={{ height: "40px" }}
                    onChange={handleTopChoose}
                  >
                    <MenuItem value={10}> 10</MenuItem>
                    <MenuItem value={15}> 15</MenuItem>
                    <MenuItem value={20}> 20</MenuItem>
                  </Select>
                </FormControl>
              </div>
            )}
          </div>
        )}
        <div className="button-group ">
          <Button
            className={view === 0 ? "button active" : "button"}
            onClick={() => setView(0)}
          >
            Graphs
          </Button>
          <Button
            className={view === 1 ? "button active" : "button"}
            onClick={() => setView(1)}
          >
            Details
          </Button>
        </div>
      </div>

      {view === 0 && (
        <div className="graph">
          {chooseGraph === 1 && (
            <>
              <div
                className="bar-graph"
                style={
                  size > 700 && size < 780
                    ? {
                        height: `${
                          selectNumber <= 10
                            ? selectNumber * 10
                            : selectNumber * 20
                        }px`,
                        width: `${
                          selectNumber <= 15
                            ? selectNumber * 60
                            : selectNumber * 40
                        }px`,
                      }
                    : {
                        height: `${
                          selectNumber <= 10
                            ? selectNumber * 50
                            : selectNumber * 30
                        }px`,
                        width: `${
                          selectNumber <= 10
                            ? selectNumber * 90
                            : selectNumber * 60
                        }px`,
                      }
                }
              >
                {!loading && (
                  <ResponsiveBar
                    data={mapData}
                    keys={key1}
                    indexBy="id"
                    colors={[
                      "#FCA3CC",
                      "#BEAEE2",
                      "#FCD8D4",
                      "#FFAFAF",
                      "#D77FA1",
                      "#EDD2F3",
                      "#FFDEFA",
                      "#F5ABC9",
                      "#FDE0DD",
                      "#FFC288",
                      "#B8B5FF",
                      "#F190B7",
                      "#FCD1D1",
                      "#6FB2D2",
                      "#F473B9",
                      "#F7F7F7",
                      "#FCF8EC",
                      "#D2F5E3",
                      "#FFC1F3",
                      "#FCCBCB",
                    ]}
                    colorBy="id"
                    margin={{ top: 50, right: 300, bottom: 50, left: 60 }}
                    padding={0.3}
                    innerPadding={5}
                    layout="vertical"
                    valueScale={{ type: "linear" }}
                    indexScale={{ type: "band", round: true }}
                    borderRadius={12}
                    borderColor={{
                      from: "color",
                      modifiers: [["darker", 3]],
                    }}
                    animate={true}
                    axisTop={null}
                    axisRight={null}
                    // axisBottom={{
                    //   tickSize: 0,
                    //   tickPadding: 5,
                    //   tickRotation: 0,
                    //   legend: `${viewName}`,
                    //   legendPosition: "middle",
                    //   legendOffset: 32,
                    //   "format": () => null,
                    // }}
                    axisBottom={null}
                    axisLeft={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 0,

                      legendPosition: "middle",
                      legendOffset: -40,
                    }}
                    labelSkipWidth={12}
                    labelSkipHeight={12}
                    labelTextColor={{
                      from: "color",
                      modifiers: [["darker", 2]],
                    }}
                    legends={[
                      {
                        dataFrom: "keys",
                        anchor: "bottom-right",
                        direction: "column",
                        justify: false,
                        translateX: 120,
                        translateY: 0,
                        itemsSpacing: 2,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: "left-to-right",
                        itemOpacity: 0.85,
                        symbolSize: 20,
                      },
                    ]}
                    theme={{
                      fontSize: 15,
                      grid: {
                        line: {
                          stroke: "#ffff",
                          strokeWidth: 0,
                          strokeDasharray: "6",
                        },
                      },
                      axis: {
                        legend: {
                          text: {
                            fontSize: 20,
                            fontWeight: 600,
                            fontFamily: "Poppins, sans-serif",
                          },
                        },
                      },
                    }}
                  />
                )}
              </div>
              {/* <h1 style={{marginTop:"-30px",fontSize:"16px",marginBottom:"20px"}}>{data.length !== 0 && `${viewName.charAt(0).toUpperCase() + viewName.slice(1)}`}</h1> */}
              {data.length !== 0 && (
                <div style={{ float: "right", marginTop: "-30px" }}>
                  <h1
                    className="h1ie"
                    style={{
                      marginBottom: "20px",
                      fontSize: "18px",
                      color: "#7F00BB",
                    }}
                  >
                    {viewName === "university" && "University "}
                  </h1>
                  <h1
                    className="pie"
                    style={{
                      marginBottom: "20px",
                      fontSize: "18px",
                      color: "#7F00BB",
                    }}
                  >
                    {viewName === "diversity" && "Diversity "}
                  </h1>
                  <h1
                    className="pie"
                    style={{
                      marginBottom: "20px",
                      fontSize: "18px",
                      color: "#7F00BB",
                    }}
                  >
                    {viewName === "graduation_year" && "Graduation Year"}
                  </h1>
                  <h1
                    className="pie"
                    style={{
                      marginBottom: "20px",
                      fontSize: "18px",
                      color: "#7F00BB",
                    }}
                  >
                    {viewName === "job_function" && "Job Function "}
                  </h1>
                  <h1
                    className="pie"
                    style={{
                      marginBottom: "20px",
                      fontSize: "18px",
                      color: "#7F00BB",
                    }}
                  >
                    {viewName === "gpa" && "GPA"}
                  </h1>
                </div>
              )}
              <div className="pagination">
                <Pagination
                  onChange={pageHandler}
                  count={Math.ceil(total_University / selectNumber)}
                  color="secondary"
                  shape="circular"
                />
              </div>
            </>
          )}
          {chooseGraph === 2 && (
            <div className="graph-pie-chart">
              <PieChart data={viewData} view={viewName} />
            </div>
          )}
        </div>
      )}
      {view === 1 && (
        <CandidatesList
          id={id}
          timeFilter={timeFilter}
          DataType={DataType}
          jobDataMiscellances={jobDataMiscellances}
          switchView={view}
        />
      )}
    </div>
  );
};

export default BodySection;
