import React, { useEffect, useState } from "react";
import {
  DataGridPro,
  GridCellParams,
  GridToolbar,
  MuiEvent,
  GridRenderCellParams,
  GridApi,
  GridColumns,
  gridColumnVisibilityModelSelector,
  GridEvents,
  GridRowGroupingModel,
  useGridApiRef,
  GridRowSpacingParams,
  GridGroupingColDefOverride,
  GridRowHeightParams,
} from "@mui/x-data-grid-pro";

import { styled } from "@mui/material/styles";

import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import moment from "moment-timezone";
import {
  Chip,
  Modal,
  Box,
  Stack,
  TextField,
  IconButton,
  FormControl,
  FormGroup,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Menu,
  Dialog,
  AppBar,
  Toolbar,
  Slide,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { grey } from "@mui/material/colors";

import { Document, Page, pdfjs } from "react-pdf";

import AffinityChips from "../UI/AffinityChips";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const AntDesignStyledDataGridPro = styled(DataGridPro)(({ theme }) => ({
  border: `1px solid ${theme.palette.mode === "light" ? "#f0f0f0" : "#303030"}`,
  color:
    theme.palette.mode === "light"
      ? "rgba(0,0,0,.85)"
      : "rgba(255,255,255,0.85)",
  backgroundColor: grey[200],
  fontFamily: "Poppins', sans-serif",
  WebkitFontSmoothing: "auto",
  letterSpacing: "normal",
  "& .MuiDataGrid-columnsContainer": {
    backgroundColor: theme.palette.mode === "light" ? "#fafafa" : "#1d1d1d",
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    borderRight: `1px solid ${
      theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
    }`,
  },
  "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
    borderBottom: `1px solid ${
      theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
    }`,
  },
  "& .MuiDataGrid-cell": {
    color:
      theme.palette.mode === "light"
        ? "rgba(0,0,0,.85)"
        : "rgba(255,255,255,0.85)",
    fontFamily: "Poppins', sans-serif",

    WebkitFontSmoothing: "auto",
    letterSpacing: "normal",
    "& .MuiDataGrid-columnsContainer": {
      backgroundColor: theme.palette.mode === "light" ? "#fafafa" : "#1d1d1d",
    },
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-colCell, .MuiDataGrid-cell": {
      borderRight: `1px solid ${
        theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
      }`,
    },
    "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
      borderBottom: `1px solid ${
        theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
      }`,
    },
    "& .MuiDataGrid-cell": {
      color:
        theme.palette.mode === "light"
          ? "rgba(0,0,0,.85)"
          : "rgba(255,255,255,0.65)",
    },
    "& .MuiPaginationItem-root": {
      borderRadius: 0,
    },
    "& .MuiCheckbox-root svg": {
      width: 40,
      height: 16,
      backgroundColor: "transparent",
      border: `1px solid ${
        theme.palette.mode === "light" ? "#d9d9d9" : "rgb(67, 67, 67)"
      }`,
      borderRadius: 2,
    },
    "& .MuiCheckbox-root svg path": {
      display: "none",
    },
    "& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg": {
      backgroundColor: "#1890ff",
      borderColor: "#1890ff",
    },
    "& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after": {
      position: "absolute",
      display: "table",
      border: "2px solid #fff",
      borderTop: 0,
      borderLeft: 0,
      transform: "rotate(45deg) translate(-50%,-50%)",
      opacity: 1,
      transition: "all .2s cubic-bezier(.12,.4,.29,1.46) .1s",
      content: '""',
      top: "50%",
      left: "39%",
      width: 5.71428571,
      height: 9.14285714,
    },
    "& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after":
      {
        width: 8,
        height: 8,
        backgroundColor: "#1890ff",
        transform: "none",
        top: "39%",
        border: 0,
      },
  },
  "css-1fajery-MuiDataGrid-root .MuiDataGrid-cell--textLeft": {
    justifyContent: "center!important",
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: 600,
  width: "96%",

  // marginRight: "20px",
  marginTop: "150px!important",
  "& .MuiFormGroup-options": {
    alignItems: "center",
    paddingBottom: theme.spacing(1),
    "& > div": {
      minWidth: 200,
      margin: theme.spacing(2),
    },
    "css-1fajery-MuiDataGrid-root .MuiDataGrid-row": {
      backgroundColor: "red",
    },
  },
}));

type GridDataType = "Employee" | "Commodity";
type GridDataThemeOption = "default" | "ant";

function escapeRegExp(value: string): string {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

interface GridPaginationSettings {
  pagination: boolean;
  autoPageSize: boolean;
  pageSize: number | undefined;
}

interface GridConfigOptions {
  size: number;
  type: GridDataType;
  pagesize: number;
  theme: GridDataThemeOption;
}

interface GridToolbarContainerProps {
  onApply: (options: GridConfigOptions) => void;
  size: number;
  type: GridDataType;
  theme: GridDataThemeOption;
}

//group table
const INITIAL_GROUPING_COLUMN_MODEL = ["university"];

const useKeepGroupingColumnsHidden = (
  apiRef: React.MutableRefObject<GridApi>,
  columns: GridColumns,
  initialModel: GridRowGroupingModel,
  leafField?: string
) => {
  const prevModel = React.useRef(initialModel);

  React.useEffect(() => {
    apiRef.current.subscribeEvent(
      GridEvents.rowGroupingModelChange,
      (newModel) => {
        // console.log("newModel", newModel);
        const columnVisibilityModel = {
          ...gridColumnVisibilityModelSelector(apiRef),
        };
        newModel.forEach((field) => {
          if (!prevModel.current.includes(field)) {
            columnVisibilityModel[field] = false;
          }
        });
        prevModel.current.forEach((field) => {
          if (!newModel.includes(field)) {
            columnVisibilityModel[field] = true;
          }
        });
        apiRef.current.setColumnVisibilityModel(columnVisibilityModel);
        prevModel.current = newModel;
      }
    );
  }, [apiRef]);

  return React.useMemo(
    () =>
      columns.map((colDef) =>
        initialModel.includes(colDef.field) ||
        (leafField && colDef.field === leafField)
          ? { ...colDef, hide: true }
          : colDef
      ),
    [columns, initialModel, leafField]
  );
};

let randomCol: any = [
  "primary",
  "secondary",
  "error",
  "info",
  "success",
  "warning",
];

const EventsTable: React.FC<{
  eventType: any;
  setEventType: any;
  eventLists: any;
  events: any;
  loading: any;
  setIndex: any;
  checkEvent: any;
}> = ({
  eventType,
  setEventType,
  eventLists,
  events,
  loading,
  setIndex,
  checkEvent,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const apiRef = useGridApiRef();
  const [searchParams] = useSearchParams();
  const [hide, setHide] = useState(true);

  const token = localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token")!)
    : "";

  const columns = [
    // { field: '_id', headerName: 'ID', width: 300 ,disableColumnSelector:true },
    {
      field: "first_name",
      headerName: "Name",
      width: 140,
    },
    {
      field: "name",
      headerName: "University",
      width: 300,
      renderCell: (params: any) => (
        <Stack direction="row" spacing={1}>
          <>
            <Chip
              label={params.row.name}
              color={randomCol[params?.row?.random]}
              style={
                params.row.name === ""
                  ? { display: "none" }
                  : {
                      backgroundColor: `${randomCol[params?.row?.random]}`,
                      color: "#ffff",
                      fontSize: "14px",
                      height: "25px",
                    }
              }
            />
            <br></br>
          </>
        </Stack>
      ),
    },
    // {
    //   field: "event_name",
    //   headerName: "Event Name",
    //   width: 600,
    // },
    // {
    //   field: "mobile",
    //   headerName: "Mobile No",
    //   width: 200,
    // },
    {
      field: "email",
      headerName: "Email",
  
      width: 200,
      // editable: true,
    },
    {
      field: "graduation_year",
      headerName: "Graduation year",
      width: 130,
      hide: hide,
    },
    {
      field: "education_level",
      headerName: "Education",
      width: 150,
      hide: hide,
    },
    {
      field: "company",
      headerName: "Company",
      width: 200,
    },
    {
      field: "industry",
      headerName: "Industry",
      width: 200,
    },

    // {
    //   field: "title",
    //   headerName: "Title",
    //   width: 200,
    // },
    // {
    //   field: "question1",
    //   headerName: "Affinity",
    //   width: 300,
    //   renderCell: (params: any) => (
    //     <Stack direction="row" spacing={1}>
    //       {params?.row?.question1 !== "-"
    //         ? params?.row?.question1?.map((Industries: any) => (
    //             <>
    //               <Chip
    //                 label={Industries}
    //                 color={randomCol[params?.row?.random]}
    //                 style={{
    //                   backgroundColor: "#7f00bb",
    //                   color: "#ffff",
    //                   fontSize: "14px",
    //                   height: "25px",
    //                 }}
    //               />
    //               <br></br>
    //             </>
    //           ))
    //         : params?.row?.question1}
    //     </Stack>
    //   ),
    // },
    // {
    //   field: "question2",
    //   headerName: "Questions",
    //   width: 300,
    // },
    {
      field: "city",
      headerName: "City",
      width: 200,
    },
    // {
    //   field: "partner_club",
    //   headerName: " Partner club",
    //   width: 200,
    // },
  ];

  const columns1 = [
    // { field: '_id', headerName: 'ID', width: 300 ,disableColumnSelector:true },
    {
      field: "first_name",
      headerName: "Name",
      width: 140,

      // editable: true,
    },

    {
      field: "education_level",
      headerName: "Education",
      hide: hide,
      width: 150,
      // editable: true,
    },
    {
      field: "email",
      headerName: "Email",
  
      width: 200,
      // editable: true,
    },
    {
      field: "graduation_year",
      headerName: "Graduation year",
      hide: hide,
      width: 130,
      // editable: true,
    },
    {
      field: "name",
      headerName: "University",

      width: 300,
      // editable: true,
      //   renderCell: (params:any) => (

      //     <Stack direction="row" spacing={1}>

      //    <><Chip label={params.row.name} color={randomCol[params?.row?.random]} style={ params.row.name === ""? {display:"none", } : {backgroundColor: `${randomCol[params?.row?.random]}`, color: "#ffff", fontSize:"14px", height:"25px" } } /><br></br></>

      //    </Stack>
      // ),
    },

    {
      field: "partner_club",
      headerName: " Partner club",

      width: 200,
      // editable: true,
    },
    // {
    //   field: "interests",
    //   headerName: "Interests",

    //   width: 700,
    //   // editable: true,
    //   renderCell: (params:any) => (

    //     <Stack direction="row" spacing={1}>

    //      {params?.row?.interests?.map((interests:any) => (

    //    <><Chip label={interests} color={randomCol[params?.row?.random]} style={{ backgroundColor: `${randomCol[params?.row?.random]}`, color: "#ffff" }} /><br></br></>
    //     ))}

    //    </Stack>
    // ),
    // },
    // {
    //   field: "objectives",
    //   headerName: "Objectives",

    //   width: 260,
    //   // editable: true,
    //   renderCell: (params:any) => (

    //     <Stack direction="row" spacing={1}>

    //      {params?.row?.objectives?.map((object:any) => (

    //    <><Chip label={object} color={randomCol[params?.row?.random]} style={{ backgroundColor: `${randomCol[params?.row?.random]}`, color: "#ffff",fontSize:"14px", height:"25px"  }} /><br></br></>
    //     ))}

    //    </Stack>
    // ),
    // },
    // {
    //   field: "preferred_careers",
    //   headerName: "Careers",

    //   width: 300,
    //   // editable: true,
    //   renderCell: (params:any) => (

    //     <Stack direction="row" spacing={1}>

    //      {params?.row?.preferred_careers?.map((careers:any) => (

    //    <><Chip label={careers} color={randomCol[params?.row?.random]} style={{ backgroundColor: `${randomCol[params?.row?.random]}`, color: "#ffff",fontSize:"14px", height:"25px"  }} /><br></br></>
    //     ))}

    //    </Stack>
    // ),
    // },
    // {
    //   field: "preferred_industries",
    //   headerName: "Industries",

    //   width: 200,

    //      renderCell: (params:any) => (

    //     <Stack direction="row" spacing={1}>

    //      {params?.row?.preferred_industries?.map((Industries:any) => (

    //    <><Chip label={Industries} color={randomCol[params?.row?.random]} style={{ backgroundColor: `${randomCol[params?.row?.random]}`, color: "#ffff",fontSize:"14px", height:"25px"  }} /><br></br></>
    //     ))}

    //    </Stack>
    // ),
    // },
    {
      field: "city",
      headerName: "City",

      width: 200,
    },
    {
      field: "company",
      headerName: "Company",

      width: 200,
    },
    {
      field: "industry",
      headerName: "Industry",

      width: 200,
    },
    {
      field: "title",
      headerName: "Title",

      width: 200,
    },
    // {
    //   field: "Question1",
    //   headerName: "Which affinity group do you associate with, if any?",
    //   width: 300,
    // },
    // {
    //   field: "Question2",
    //   headerName: "Enter any questions you have for TCV or the speakers",
    //   width: 300,
    // },
    {
      field: "city",
      headerName: "City",

      width: 200,
    },

    // {
    //   field: "affinity_group",
    //   headerName: "Affinity Group",

    //   width: 270,
    //   renderCell: (params:any) => (

    //     <Stack direction="row" spacing={1}>

    //      {params?.row?.affinity_group?.map((affinity:any) => (

    //    <Chip label={affinity} color ={randomCol[params?.row?.random]} style={ {backgroundColor : `${randomCol[params?.row?.random]}` , color:"#ffff" }}/>
    //     ))}

    //    </Stack>
    // ),
    //   // editable: true,
    // },
  ];

  const [isAntDesign, setIsAntDesign] = useState<boolean>(false);
  const [type, setType] = useState<GridDataType>("Commodity");

  const [groupColumn, setGroupColumn] = useState<string>();
  const [randomGroup, setRandomGroup] = useState(false);
  const [tableView, settableView] = useState();
  const [rows, setRow] = useState([]);
  const [cols, setCols] = useState(columns);
  const [size, setSize] = useState(100);
  const [toggleOpen, setToggleOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [resume, setResume] = useState("");
  const [subGroupColumn, setSubGroupColumn] = useState();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchRow, setSearchRow] = useState([]);
  const [checked, setChecked] = useState<any[]>([]);
  const [checkOpen, setCheckOpen] = useState(false);
  const [expandTable, setExpandTable] = useState(false);
  let id = searchParams.get("id");

  const windowSize = window.screen.availWidth;

  const [rowGroupingModel, setRowGroupingModel] = React.useState(
    INITIAL_GROUPING_COLUMN_MODEL
  );

  const data = {
    ...cols,
    ...rows,
  };

  useEffect(() => {
    setEventType(id);
  }, [id]);

  useEffect(() => {
    if (id != "63fb520df3ba0a790b4a5461") {
      setHide(false);
    }
  }, [id]);

  const [pagination, setPagination] = React.useState<GridPaginationSettings>({
    pagination: false,
    autoPageSize: false,
    pageSize: undefined,
  });

  const getActiveTheme = () => {
    return isAntDesign ? "ant" : "default";
  };

  const viewResumeHandler = (link: any) => {
    setOpenModal(true);
    setResume(link.value);
  };

  const handleApplyClick = (settings: any) => {
    if (size !== settings.size) {
      setSize(settings.size);
    }

    if (type !== settings.type) {
      setType(settings.type);
    }

    if (getActiveTheme() !== settings.theme) {
      setIsAntDesign(!isAntDesign);
    }

    const newPaginationSettings: GridPaginationSettings = {
      pagination: settings.pagesize !== -1,
      autoPageSize: settings.pagesize === 0,
      pageSize: settings.pagesize > 0 ? settings.pagesize : undefined,
    };

    setPagination(
      (
        currentPaginationSettings: GridPaginationSettings
      ): GridPaginationSettings => {
        if (
          currentPaginationSettings.pagination ===
            newPaginationSettings.pagination &&
          currentPaginationSettings.autoPageSize ===
            newPaginationSettings.autoPageSize &&
          currentPaginationSettings.pageSize === newPaginationSettings.pageSize
        ) {
          return currentPaginationSettings;
        }
        return newPaginationSettings;
      }
    );
  };

  const DataGridComponent = isAntDesign
    ? AntDesignStyledDataGridPro
    : DataGridPro;

  useEffect(() => {
    if (
      events?.records[0]?.event_questions !== null &&
      events?.records[0]?.event_questions?.length !== 0
    ) {
      let tempCols = [...columns];
      events?.records[0]?.event_questions?.map((que: any) => {
        if (tempCols.find((col: any) => col.field !== que.name)) {
          if (que.key === "affinity_groups") {
            tempCols.push(
              {
                field: que.name,
                headerName: que.name,
                width: 300,
                renderCell: (params: any) => (
                  <Stack direction="row" spacing={1}>
                    {params?.row?.[que.name] !== "-"
                      ? params?.row?.[que.name]
                          .split(",")
                          .map((affinity: string, index: number) => (
                            <>
                              <AffinityChips affinity={affinity} />
                              <br></br>
                            </>
                          ))
                      : params?.row?.question1}
                  </Stack>
                ),
              },
              {
                field: "partner_club",
                headerName: " Partner club",

                width: 200,
                // editable: true,
              }
            );
          } else if (que.key === "resume") {
            tempCols.push({
              field: que.name,
              headerName: que.name,
              width: 300,
              renderCell: (params: any) => (
                <Button
                  onClick={() => viewResumeHandler(params)}
                  style={{ padding: "4px 10px", fontSize: "11px" }}
                >
                  View Resume
                </Button>
              ),
            });
          } else {
            tempCols.push({
              field: que.name,
              headerName: que.name,
              width: 300,
            });
          }
        } else {
        }
      });

      setCols(tempCols);
    } else {
      cols.push({
        field: "partner_club",
        headerName: " Partner club",

        width: 200,
        // editable: true,
      });
    }
  }, [events]);

  useEffect(() => {
    if (!loading) {
      let data1: any = [];
      events?.records?.map((eventData: any, index: number) => {
        const Education_level = () => {
          const month = new Date().getMonth();
          const year = new Date().getFullYear();
          if (
            (eventData?.created_by?.education &&
              eventData?.created_by?.education[0]?.graduation_year < year) ||
            (eventData?.created_by?.education[0]?.graduation_year === year &&
              month > 7)
          ) {
            if (eventData?.created_by?.education[0]?.education_type === 0)
              return "Professional Undergraduate";
            else return "Professional MBA";
          } else if (eventData?.created_by?.education[0]?.education_type === 0)
            return "Undergraduate";
          else return "MBA";
        };
        const Time = moment(eventData?.timestamp).format("LT");
        const year =
          eventData?.created_by?.education[0]?.graduation_year === ""
            ? " - "
            : eventData?.created_by?.education[0]?.graduation_year;
        const partner =
          eventData?.created_by?.education[0]?.partner_club === ""
            ? " - "
            : eventData?.created_by?.education[0]?.partner_club;
        const university =
          eventData?.created_by?.education[0]?.name === ""
            ? " - "
            : eventData?.created_by?.education[0]?.name;

        const rndInt = Math.floor(Math.random() * 5) + 1;
        const city1 = eventData?.created_by?.work_experiences
          ? eventData?.created_by?.work_experiences[0]?.city
          : "-";
        const company = eventData?.created_by?.work_experiences
          ? eventData?.created_by?.work_experiences[0]?.company
          : "-";
        const title = eventData?.created_by?.work_experiences
          ? eventData?.created_by?.work_experiences[0]?.industry
          : "-";
        const industry = eventData?.created_by?.work_experiences
          ? eventData?.created_by?.work_experiences[0]?.title
          : "-";
        const Question1 = eventData?.event_questions
          ? eventData?.event_questions[0]?.values
          : "-";
        const Question2 = eventData?.event_questions
          ? eventData?.event_questions[1]?.values
          : "-";

        let tempQues: any = [];
        let newQues: any = {};
        if (eventData?.event_questions !== null) {
          tempQues = eventData?.event_questions?.slice();
          newQues = tempQues?.reduce((que: any, curr: any) => {
            que[curr.name] = !curr?.values?.length
              ? "-"
              : curr.values.join(",");
            return que;
          }, {});
        }

        if (newQues === {}) {
          data1.push({
            _id: eventData?._id,
            index: index,
            first_name:
              eventData?.created_by?.first_name +
              " " +
              eventData?.created_by?.last_name,
            act:
              eventData?.created_by.act === 0 ? "-" : eventData?.created_by.act,
            gpa:
              eventData?.created_by.gpa === 0 ? "-" : eventData?.created_by.gpa,
            gmat:
              eventData?.created_by.gmat === 0
                ? "-"
                : eventData?.created_by.gmat,
            sat:
              eventData?.created_by.sat === 0 ? "-" : eventData?.created_by.sat,
            email:
              eventData?.created_by?.email === ""
                ? "-"
                : eventData?.created_by.email,

            graduation_year: year,
            partner_club: partner,

            name: university,
            education_level: Education_level(),
            interests: eventData?.created_by?.preferences?.interests,
            objectives: eventData?.created_by?.preferences?.objectives,
            preferred_careers:
              eventData?.created_by?.preferences?.preferred_careers,
            preferred_industries:
              eventData?.created_by?.preferences?.preferred_industries,
            // time: Time,
            random: rndInt,
            city: city1,
            company: company,
            industry: industry,
            title: title,
          });
        } else {
          data1.push({
            _id: eventData?._id,
            index: index,
            first_name:
              eventData?.created_by?.first_name +
              " " +
              eventData?.created_by?.last_name,
            act:
              eventData?.created_by.act === 0 ? "-" : eventData?.created_by.act,
            gpa:
              eventData?.created_by.gpa === 0 ? "-" : eventData?.created_by.gpa,
            gmat:
              eventData?.created_by.gmat === 0
                ? "-"
                : eventData?.created_by.gmat,
            sat:
              eventData?.created_by.sat === 0 ? "-" : eventData?.created_by.sat,
            email:
              eventData?.created_by.email === ""
                ? "-"
                : eventData?.created_by.email,

            graduation_year: year,
            partner_club: partner,

            name: university,
            education_level: Education_level(),
            interests: eventData?.created_by?.preferences?.interests,
            objectives: eventData?.created_by?.preferences?.objectives,
            preferred_careers:
              eventData?.created_by?.preferences?.preferred_careers,
            preferred_industries:
              eventData?.created_by?.preferences?.preferred_industries,
            // time: Time,
            random: rndInt,
            city: city1,
            company: company,
            industry: industry,
            title: title,
            ...newQues,
          });
        }
      });
      setRow(data1);
      setSearchRow(data1);
    }
  }, [eventType, events]);

  const handlePaginationChange = React.useCallback(
    (event) => {
      setEventType(event.target.value);
      navigate(`?view=list&display=all&id=${event.target.value}`);
    },
    [events, rows]
  );
  const handleViewChange = React.useCallback(
    (event) => {
      setAnchorElView(null);
      let a = event.target.value;
      settableView(a);

      if (a === 1) {
        setRandomGroup(false);
        setCheckOpen(false);
      }

      if (a === 2) {
        setRandomGroup(true);
        setGroupColumn("name");
      }
    },
    [events, rows]
  );
  const handleGroupColumnChange = React.useCallback(
    (event) => {
      setGroupColumn(event.target.value);
    },
    [events, rows]
  );
  const handleSubGroupColumnChange = React.useCallback(
    (event) => {
      setSubGroupColumn(event.target.value);
    },
    [events, rows]
  );

  const handleapplyChange = () => {
    setCheckOpen(false);
    setRowGroupingModel([`${groupColumn}`, `${subGroupColumn}`]);

    if (tableView === 1) {
      setToggleOpen(false);
    }
    if (tableView === 2) {
      setToggleOpen(true);
    }
  };

  const handleOnCellClick = (params: any) => {
    if (params.field === "first_name") {
      setIndex(params.row.index);
    }
  };

  const handleOnCellClickGroup = (params: any) => {
    if (
      params.rowNode.isAutoGenerated === false &&
      params.field === "first_name"
    ) {
      setIndex(params.row.index);
    }
  };

  function onDocumentLoadSuccess(props: any) {
    setNumPages(props.numPages);
  }
  const rowGroupingModelStr = rowGroupingModel.join("-");
  const getRowSpacing = React.useCallback((params: GridRowSpacingParams) => {
    return {
      top: params.isFirstVisible ? 0 : 5,
      bottom: params.isLastVisible ? 0 : 5,
      background: grey[200],
    };
  }, []);

  // search

  const [searchText, setSearchText] = React.useState("");

  const SarchHandler = (e: any) => {
    setSearchText(e.target.value);
    const searchRegex = new RegExp(escapeRegExp(searchText), "i");
    const filteredRows = rows?.filter((row: any) => {
      return Object?.keys(row)?.some((field: any) => {
        return searchRegex?.test(row[field]?.toString());
      });
    });
    setRow(filteredRows);
  };
  const clearSearch = () => {
    setSearchText("");
    setRow(searchRow);
  };
  React.useEffect(() => {
    setRow(rows);
    if (searchText === "") {
      setRow(searchRow);
    }
  }, [rows]);

  // search

  // Drop Down Button
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [anchorElView, setAnchorElView] = React.useState<null | HTMLElement>(
    null
  );
  const openView = Boolean(anchorElView);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // view Button
  const handleClickView = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElView(event.currentTarget);
  };
  const handleCloseView = (event: any) => {
    setAnchorElView(null);
  };

  // group

  const handleToggle = (value: any) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const CheckHandler = () => {
    setCheckOpen(true);
  };

  const paymentHandler = () => {
    navigate(`?view=payment&id=${eventType}`);
  };

  return (
    <div className="joblist">
      <div className="heading-button">
        <h1 className="head"> Events</h1>
        <FormControl sx={{ m: 1, minWidth: 600 }}>
          <InputLabel id="demo-simple-select-autowidth-label">Event</InputLabel>
          <Select
            value={eventType}
            onChange={handlePaginationChange}
            autoWidth
            label="Event"
            style={{
              width: "600px",
              overflow: "hidden",
              height: "45px",
              color: "#7f00bb",
              background: "rgba(127, 0, 187, 0.08)",
              fontWeight: "500",
            }}
            MenuProps={{
              PaperProps: { sx: { maxHeight: 500, maxWidth: 600 } },
            }}
          >
            <MenuItem value="0">Select the Event</MenuItem>
            {eventLists?.records?.map((event: any) => (
              <MenuItem value={event?._id}>{event?.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      {eventType !== null && eventType !== undefined ? (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              paddingLeft: "-5px",
              marginTop: "10px",
              marginBottom: "50px",
            }}
          >
            <Box
              sx={{
                p: 0.5,
                pb: 0,
              }}
            >
              <TextField
                className="search"
                variant="standard"
                value={searchText}
                onChange={SarchHandler}
                placeholder="Search inside the table data"
                InputProps={{
                  disableUnderline: true,
                  startAdornment: <SearchIcon style={{ color: "#999999" }} />,
                  endAdornment: (
                    <IconButton
                      title="Clear"
                      aria-label="Clear"
                      size="small"
                      style={{ visibility: searchText ? "visible" : "hidden" }}
                      onClick={clearSearch}
                    >
                      <CloseIcon />
                    </IconButton>
                  ),
                }}
                sx={{
                  width: {
                    xs: 1,
                    sm: "auto",
                  },
                  m: (theme) => theme.spacing(1, 0.5, 1.5),
                  "& .MuiSvgIcon-root": {
                    mr: 0.5,
                  },
                  "& .MuiInput-underline:before": {
                    borderBottom: 1,
                    borderColor: "divider",
                  },
                }}
              />
            </Box>

            <div>
              <FormControl sx={{ m: 1 }} className="formControal">
                <InputLabel id="demo-simple-select-autowidth-label">
                  View
                </InputLabel>
                <Select
                  defaultValue={1}
                  onChange={handleViewChange}
                  autoWidth
                  label="View"
                  className="select"
                  MenuProps={{
                    PaperProps: { sx: { maxHeight: 300, width: 150 } },
                  }}
                >
                  <MenuItem value={1}>List view</MenuItem>
                  <MenuItem value={2}>Group View</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="group">
              {randomGroup && (
                <FormControl sx={{ m: 1 }} className="formControal">
                  <InputLabel id="demo-simple-select-autowidth-label">
                    Group By
                  </InputLabel>
                  <Select
                    onChange={handleGroupColumnChange}
                    autoWidth
                    defaultValue={0}
                    label="Group By"
                    className="select"
                  >
                    {columns1.map((columns: any, index) => (
                      <MenuItem
                        value={columns.field !== "resume" && columns.field}
                      >
                        {columns.field !== "resume" && columns.headerName}
                      </MenuItem>
                    ))}
                    {/* <MenuItem value={1}>List view</MenuItem>
              <MenuItem value={2}>Group view</MenuItem> */}
                  </Select>
                </FormControl>
              )}
              {randomGroup && (
                <FormControl sx={{ m: 1 }} className="formControal">
                  <InputLabel id="demo-simple-select-autowidth-label">
                    Group By 2
                  </InputLabel>
                  <Select
                    onChange={handleSubGroupColumnChange}
                    autoWidth
                    label="Group By 2"
                    defaultValue={0}
                    className="select"
                  >
                    <MenuItem value="0">None</MenuItem>
                    {columns1.map((columns: any, index) => (
                      <MenuItem
                        value={columns.field !== "resume" && columns.field}
                      >
                        {columns.field !== "resume" && columns.headerName}
                      </MenuItem>
                    ))}
                    {/* <MenuItem value={1}>List view</MenuItem>
              <MenuItem value={2}>Group view</MenuItem> */}
                  </Select>
                </FormControl>
              )}
            </div>

            <button
              onClick={handleapplyChange}
              style={{
                background: "#7f00bb",
                color: "white",
                outline: "none",
                fontSize: "20px",
                height: "40px",
                padding: "3px 30px",
                border: "none",
                borderRadius: "10px",
                cursor: "pointer",

                display: "flex",
                alignItems: "center",
              }}
            >
              Apply
            </button>

            <button
              onClick={() => setExpandTable(true)}
              style={{
                background: "#7f00bb",
                color: "white",
                outline: "none",
                fontSize: "20px",
                height: "40px",
                padding: "3px 30px",
                border: "none",
                borderRadius: "10px",
                cursor: "pointer",
                marginLeft: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              Expand
            </button>
          </div>

          {checkEvent && (
            <div
              className="payment-button"
              style={{
                textAlign: "right",
                display: "flex",
                position: "relative",
                left: "75%",
                cursor: "pointer",
                zIndex: "1111",
                width: "260px",
                marginBottom: "-98px",
              }}
              onClick={paymentHandler}
            >
              <button
                style={{
                  outline: "none",
                  borderRadius: "8px",
                  background: "#313131",
                  color: "#ffffff",
                  padding: "10px 30px",
                  cursor: "pointer",
                }}
              >
                Payment Status
              </button>
            </div>
          )}
          <div className="body">
            {!toggleOpen && (
              <StyledBox>
                <DataGridComponent
                  columns={cols}
                  getRowId={(row) => row._id}
                  rows={rows}
                  {...data}
                  components={{
                    Toolbar: GridToolbar,
                  }}
                  loading={loading}
                  checkboxSelection
                  disableSelectionOnClick
                  rowThreshold={0}
                  onCellClick={handleOnCellClick}
                  getRowSpacing={getRowSpacing}
                  {...pagination}
                />
              </StyledBox>
            )}
            {toggleOpen && (
              <div
                style={{
                  height: "600px",
                  width: "96%",
                  marginRight: "60px",
                  marginTop: "145px",
                }}
              >
                <DataGridPro
                  getRowId={(row) => row._id}
                  rows={rows}
                  {...data}
                  components={{
                    Toolbar: GridToolbar,
                  }}
                  apiRef={apiRef}
                  columns={columns1}
                  onCellClick={handleOnCellClickGroup}
                  rowGroupingModel={rowGroupingModel}
                  experimentalFeatures={{
                    rowGrouping: true,
                  }}
                  getRowSpacing={getRowSpacing}
                />
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="default-text">
          <div>
            <img
              src="https://s1.ecnstatic.com/ecn/images/common/laptop.png"
              alt=""
            />
            <p>Please Select an Event</p>
          </div>
        </div>
      )}

      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          setResume("");
        }}
      >
        <Box sx={modal}>
          <Document
            file={`${resume}&access_token=${token?.access_token}`}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={console.error}
          >
            <Page pageNumber={pageNumber} />
          </Document>
        </Box>
      </Modal>

      <Dialog
        fullScreen
        open={expandTable}
        onClose={() => setExpandTable(false)}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <Button
              autoFocus
              color="inherit"
              onClick={() => setExpandTable(false)}
            >
              <CloseIcon />
            </Button>
          </Toolbar>
        </AppBar>
        <div className="joblist" style={{ width: "98%", margin: "auto" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              paddingLeft: "-5px",
              marginTop: "10px",
              marginBottom: "20px",
            }}
          >
            <Box
              sx={{
                p: 0.5,
                pb: 0,
              }}
            >
              <TextField
                className="search"
                variant="standard"
                value={searchText}
                onChange={SarchHandler}
                placeholder="Search inside the table data"
                InputProps={{
                  disableUnderline: true,
                  startAdornment: <SearchIcon style={{ color: "#999999" }} />,
                  endAdornment: (
                    <IconButton
                      title="Clear"
                      aria-label="Clear"
                      size="small"
                      style={{ visibility: searchText ? "visible" : "hidden" }}
                      onClick={clearSearch}
                    >
                      <CloseIcon />
                    </IconButton>
                  ),
                }}
                sx={{
                  width: {
                    xs: 1,
                    sm: "auto",
                  },
                  m: (theme) => theme.spacing(1, 0.5, 1.5),
                  "& .MuiSvgIcon-root": {
                    mr: 0.5,
                  },
                }}
              />
            </Box>

            <FormControl sx={{ m: 1 }} className="formControal">
              <InputLabel id="demo-simple-select-autowidth-label">
                View
              </InputLabel>
              <Select
                defaultValue={1}
                onChange={handleViewChange}
                autoWidth
                label="View"
                className="select"
                MenuProps={{
                  PaperProps: { sx: { maxHeight: 300, width: 150 } },
                }}
              >
                <MenuItem value={1}>List view</MenuItem>
                <MenuItem value={2}>Group View</MenuItem>
              </Select>
            </FormControl>

            <div className="group">
              {randomGroup && (
                <FormControl sx={{ m: 1 }} className="formControal">
                  <InputLabel id="demo-simple-select-autowidth-label">
                    Group By
                  </InputLabel>
                  <Select
                    onChange={handleGroupColumnChange}
                    autoWidth
                    defaultValue={0}
                    label="Group By"
                    className="select"
                  >
                    {columns1.map((columns: any, index) => (
                      <MenuItem
                        value={columns.field !== "resume" && columns.field}
                      >
                        {columns.field !== "resume" && columns.headerName}
                      </MenuItem>
                    ))}
                    {/* <MenuItem value={1}>List view</MenuItem>
              <MenuItem value={2}>Group view</MenuItem> */}
                  </Select>
                </FormControl>
              )}
              {randomGroup && (
                <FormControl sx={{ m: 1 }} className="formControal">
                  <InputLabel id="demo-simple-select-autowidth-label">
                    Group By 2
                  </InputLabel>
                  <Select
                    onChange={handleSubGroupColumnChange}
                    autoWidth
                    label="Group By 2"
                    defaultValue={0}
                    className="select"
                  >
                    <MenuItem value="0">None</MenuItem>
                    {columns1.map((columns: any, index) => (
                      <MenuItem
                        value={columns.field !== "resume" && columns.field}
                      >
                        {columns.field !== "resume" && columns.headerName}
                      </MenuItem>
                    ))}
                    {/* <MenuItem value={1}>List view</MenuItem>
              <MenuItem value={2}>Group view</MenuItem> */}
                  </Select>
                </FormControl>
              )}
            </div>

            <button
              onClick={handleapplyChange}
              style={{
                background: "#7f00bb",
                color: "white",
                outline: "none",
                fontSize: "20px",
                height: "40px",
                padding: "3px 30px",
                border: "none",
                borderRadius: "10px",
                cursor: "pointer",

                display: "flex",
                alignItems: "center",
              }}
            >
              Apply
            </button>

            <button
              onClick={() => setExpandTable(false)}
              style={{
                background: "#7f00bb",
                color: "white",
                outline: "none",
                fontSize: "20px",
                height: "40px",
                padding: "3px 30px",
                border: "none",
                borderRadius: "10px",
                cursor: "pointer",
                marginLeft: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              Collapse
            </button>
          </div>

          <div className="body">
            {!toggleOpen && (
              <StyledBox style={{ width: "100%" }}>
                <DataGridComponent
                  columns={cols}
                  getRowId={(row) => row._id}
                  rows={rows}
                  {...data}
                  components={{
                    Toolbar: GridToolbar,
                  }}
                  loading={loading}
                  checkboxSelection
                  disableSelectionOnClick
                  rowThreshold={0}
                  onCellClick={handleOnCellClick}
                  getRowSpacing={getRowSpacing}
                  {...pagination}
                />
              </StyledBox>
            )}
            {toggleOpen && (
              <div
                style={{
                  height: "600px",
                  marginRight: "60px",
                  marginTop: "100px",
                }}
              >
                <DataGridPro
                  getRowId={(row) => row._id}
                  rows={rows}
                  {...data}
                  components={{
                    Toolbar: GridToolbar,
                  }}
                  apiRef={apiRef}
                  columns={columns1}
                  onCellClick={handleOnCellClickGroup}
                  rowGroupingModel={rowGroupingModel}
                  experimentalFeatures={{
                    rowGrouping: true,
                  }}
                  getRowSpacing={getRowSpacing}
                />
              </div>
            )}
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default EventsTable;

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const modal = {
  display: "flex",
  justifyContent: "center",
  margin: "auto",
  backgroundColor: "white",
  width: "fit-content",
  height: "1240px",
  overflowY: "scroll",
  minWidth: "500px",
  maxWidth: "874px",
  maxHeight: "90vh",
  borderRadius: "10px",
  marginTop: "50px",
};
