import axios from "../axiosInterceptor";
import {
  JOB_ANALYTICS_REQUEST,
  JOB_ANALYTICS_SUCCESS,
  JOB_ANALYTICS_FAIL,
  JOB_ANALYTICS_MISCELLANCEOUS_REQUEST,
  JOB_ANALYTICS_MISCELLANCEOUS_SUCCESS,
  JOB_ANALYTICS_MISCELLANCEOUS_FAIL,
  JOB_ANALYTICS_DASHBOARD_REQUEST,
  JOB_ANALYTICS_DASHBOARD_SUCCESS,
  JOB_ANALYTICS_DASHBOARD_FAIL,
} from "../Constants/JobAnalyticConstants";

const BASE = process.env.REACT_APP_BACKEND;
const ANALYTICS_BASE = process.env.REACT_APP_ANALYTICS_BASE;

export const jobAnalytics = (jobData: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: JOB_ANALYTICS_REQUEST,
    });

    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token")!)
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    };

    const { data } = await axios.post(
      `${ANALYTICS_BASE}/Analytics`,
      jobData,
      config
    );

    // Dispatch the success action
    dispatch({
      type: JOB_ANALYTICS_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: JOB_ANALYTICS_FAIL,
      payload: error.message,
    });
  }
};

export const jobAnalyticsMiscellaneous =
  (jobData: any) => async (dispatch: any) => {
    try {
      dispatch({
        type: JOB_ANALYTICS_MISCELLANCEOUS_REQUEST,
      });

      const token = localStorage.getItem("token")
        ? JSON.parse(localStorage.getItem("token")!)
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${token.access_token}`,
        },
      };

      const { data } = await axios.post(
        `${ANALYTICS_BASE}/AnalyticsMiscellaneous`,
        jobData,
        config
      );

    

      // Dispatch the success action
      dispatch({
        type: JOB_ANALYTICS_MISCELLANCEOUS_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: JOB_ANALYTICS_MISCELLANCEOUS_FAIL,
        payload: error.message,
      });
    }
  };

export const jobAnalyticsDashBoard =
  (jobData: any) => async (dispatch: any) => {
    try {
      dispatch({
        type: JOB_ANALYTICS_DASHBOARD_REQUEST,
      });

      const token = localStorage.getItem("token")
        ? JSON.parse(localStorage.getItem("token")!)
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${token.access_token}`,
        },
      };

      const { data } = await axios.post(
        `${ANALYTICS_BASE}/AnalyticsDashboard`,
        jobData,
        config
      );



      // Dispatch the success action
      dispatch({
        type: JOB_ANALYTICS_DASHBOARD_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: JOB_ANALYTICS_DASHBOARD_FAIL,
        payload: error.message,
      });
    }
  };
