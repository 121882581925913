export const JOB_ANALYTICS_REQUEST = "JOB_ANALYTICS_REQUEST";
export const JOB_ANALYTICS_SUCCESS = "JOB_ANALYTICS_SUCCESS";
export const JOB_ANALYTICS_FAIL = "JOB_ANALYTICS_FAIL";

export const JOB_ANALYTICS_MISCELLANCEOUS_REQUEST =
  "JOB_ANALYTICS_MISCELLANCEOUS_REQUEST";
export const JOB_ANALYTICS_MISCELLANCEOUS_SUCCESS =
  "JOB_ANALYTICS_MISCELLANCEOUS_SUCCESS";
export const JOB_ANALYTICS_MISCELLANCEOUS_FAIL =
  "JOB_ANALYTICS_MISCELLANCEOUS_FAIL";

export const JOB_ANALYTICS_DASHBOARD_REQUEST =
  "JOB_ANALYTICS_DASHBOARD_REQUEST";
export const JOB_ANALYTICS_DASHBOARD_SUCCESS =
  "JOB_ANALYTICS_DASHBOARD_SUCCESS";
export const JOB_ANALYTICS_DASHBOARD_FAIL = "JOB_ANALYTICS_DASHBOARD_FAIL";
