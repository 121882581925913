import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";

import { getCandidatesList } from "../../../redux/Actions/jobActions";
import { getEventData } from "../../../redux/Actions/eventActions";
import { jobAnalyticsDashBoard } from "../../../redux/Actions/JobAnalyticAction";

import EventAnalyticTable from "./EventAnalyticTable";
import ViewJobTable from "./ViewJobTable";
import JobUserTable from "./JobUserTable";

const CandidatesList: React.FC<{
  id: any;
  timeFilter: any;
  DataType: any;
  jobDataMiscellances: any;
  switchView: any;
}> = ({ id, timeFilter, DataType, jobDataMiscellances, switchView }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { events } = useSelector((state: any) => state.eventData);
  const { candidateList } = useSelector((state: any) => state.jonCandidateList);
  const { eventLists } = useSelector((state: any) => state.eventList);
  const { jobDataDashBoard, loading } = useSelector(
    (state: any) => state.jobDataAnalyticsDashBoard
  );

  const [customLableId, setCustomLableId] = useState("");
  const [jobType, setJobType] = useState("");
  const [viewType, setViewType] = useState<any>(2);
  const [pageSize, setPageSize] = useState<any>(1);
  const [index, setIndex] = useState(-1);
  const [searchParams] = useSearchParams();
  const [checkEvent, setCheckEvent] = useState<any>();
  const [data, setData] = useState<any>();

  let view = searchParams.get("view");
  let tabId = searchParams.get("id");
  const { jobList, error } = useSelector((state: any) => state.jobList);
  useEffect(() => {
    if (view === "jobs" && viewType === 2 && id) {
      dispatch(getCandidatesList(id));
    }
    if (viewType != 2 && id) {
      var d = new Date();
      var ts = Math.floor(Date.now() / 1000);
      dispatch(
        jobAnalyticsDashBoard({
          id: id,
          startTime: timeFilter === 9999999999 ? 0 : timeFilter,
          endTime: timeFilter === 9999999999 ? 9999999999 : ts,
          type: viewType,
          skip: 0,
          limit: 15000,
          analyticsType: DataType,
          customLableId: customLableId,
        })
      );
    }

    if (view === "events" && id && viewType === 2) {
      dispatch(getEventData(id));
    }
  }, [id, view, viewType, timeFilter, customLableId, switchView, tabId]);

  useEffect(() => {
    if (view === "jobs") {
      if (jobDataMiscellances?.apply.visible) {
        setViewType(2);
        setCustomLableId("");
      } else if (jobDataMiscellances?.click?.visible) {
        setViewType("JOB_APPLY");
        setCustomLableId("");
      } else if (jobDataMiscellances?.view?.visible) {
        setViewType("JOB_VIEW");
        setCustomLableId("");
      } else if (jobDataMiscellances?.customLables?.length != 0) {
        let id = jobDataMiscellances?.customLables
          ? jobDataMiscellances.customLables.length === 0
            ? ""
            : jobDataMiscellances.customLables[0]?.id
          : undefined;

        setViewType("CUSTOM_LABLE");
        setCustomLableId(id);
      }
    } else {
      if (jobDataMiscellances?.apply.visible) {
        setViewType(2);
        setCustomLableId("");
      } else if (jobDataMiscellances?.click?.visible) {
        setViewType("EVENT_APPLY");
        setCustomLableId("");
      } else if (jobDataMiscellances?.view?.visible) {
        setViewType("EVENT_VIEW");
        setCustomLableId("");
      } else if (jobDataMiscellances?.customLables?.length != 0) {
        let id = jobDataMiscellances?.customLables
          ? jobDataMiscellances.customLables.length === 0
            ? ""
            : jobDataMiscellances.customLables[0]?.id
          : undefined;

        setViewType("CUSTOM_LABLE");
        setCustomLableId(id);
      }
    }
  }, [id, events, tabId]);

  useEffect(() => {
    if (jobDataDashBoard) {
      setData(jobDataDashBoard);
    }
  }, [jobDataDashBoard, tabId]);

  console.log(jobDataDashBoard);
  return (
    <div className="candidates-v2">
      {view === "jobs" ? (
        <>
          {viewType === 2 ? (
            <JobUserTable
              candidateList={candidateList}
              loading={loading}
              jobType={id}
              setJobType={setJobType}
              setIndex={setIndex}
              setViewType={setViewType}
              viewType={viewType}
              jobDataMiscellances={jobDataMiscellances}
              setCustomLableId={setCustomLableId}
              customLableId={customLableId}
            />
          ) : (
            <ViewJobTable
              candidateList={data}
              loading={loading}
              jobType={id}
              setJobType={setJobType}
              setIndex={setIndex}
              setViewType={setViewType}
              viewType={viewType}
              jobDataMiscellances={jobDataMiscellances}
              setCustomLableId={setCustomLableId}
              customLableId={customLableId}
              setPageSize={setPageSize}
              pageSize={pageSize}
            />
          )}
        </>
      ) : (
        <>
          {viewType === 2 ? (
            <EventAnalyticTable
              events={events}
              loading={loading}
              setIndex={setIndex}
              checkEvent={checkEvent}
              setViewType={setViewType}
              viewType={viewType}
              jobDataMiscellances={jobDataMiscellances}
              setCustomLableId={setCustomLableId}
              customLableId={customLableId}
            />
          ) : (
            <ViewJobTable
              candidateList={data}
              loading={loading}
              jobType={id}
              setJobType={setJobType}
              setIndex={setIndex}
              setViewType={setViewType}
              viewType={viewType}
              jobDataMiscellances={jobDataMiscellances}
              setCustomLableId={setCustomLableId}
              customLableId={customLableId}
              setPageSize={setPageSize}
              pageSize={pageSize}
            />
          )}
        </>
      )}
    </div>
  );
};

export default CandidatesList;
