import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Avatar,
  Button,
  Typography,
  TextField,
  Chip,
  Box,
  Modal,
} from "@mui/material";

import banner from "../../assets/images/candidate/banner.png";

import { Document, Page, pdfjs } from "react-pdf";
import BasicDetails from "./Sub/BasicDetails";
import { getResumeToken } from "../../redux/Actions/jobActions";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// SwiperCore.use([Navigation]);

const CandidateDetailCard: React.FC<{ data: any; events?: boolean }> = ({
  data,
  events,
}) => {
  const dispatch = useDispatch();

  const { resumeAccess, success } = useSelector(
    (state: any) => state.resumeToken
  );

  const [openModal, setOpenModal] = useState(false);
  const [resume, setResume] = useState("");
  const [type, setType] = useState("view");
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function getQuestion(que: any, index: number) {
    switch (que.type) {
      case 0:
        return (
          <>
            <Typography
              style={{ color: "black", fontWeight: "600", marginTop: 10 }}
            >
              {index + 1 + ". " + que.name}
            </Typography>
            <Typography style={{ fontSize: 16 }}>{que.values[0]}</Typography>
          </>
        );
      case 4:
        return (
          <>
            <Typography
              style={{ color: "black", fontWeight: "600", marginTop: 10 }}
            >
              {index + 1 + ". " + que.name}
            </Typography>
            {que.values.map((opt: string, i: number) => (
              <Chip
                key={i}
                label={opt}
                color="primary"
                variant="filled"
                style={{
                  borderRadius: "12px",
                  margin: "5px",
                  background: "#7F00BB",
                  fontSize: 16,
                  padding: "15px",
                }}
              />
            ))}
          </>
        );
      case 5:
        return (
          <>
            <Typography
              style={{ color: "black", fontWeight: "600", marginTop: 10 }}
            >
              {index + 1 + ". " + que.name}
            </Typography>
            {que.values.map((opt: string, i: number) => (
              <Chip
                key={i}
                label={opt}
                color="primary"
                variant="filled"
                style={{
                  borderRadius: "12px",
                  margin: "5px",
                  background: "#7F00BB",
                  fontSize: 16,
                  padding: "15px",
                }}
              />
            ))}
          </>
        );
      case 6:
        return (
          <>
            <Typography
              style={{ color: "black", fontWeight: "600", marginTop: 10 }}
            >
              {index + 1 + ". " + que.name}
            </Typography>
            {que?.values[0] ? (
              <Button
                onClick={() => downloadResumeHandler(que.values[0])}
                style={{ background: "#7F00BB" }}
                variant="contained"
              >
                RESUME
              </Button>
            ) : (
              <Button
                style={{ background: "#a782b870", color: "#ffffff" }}
                variant="contained"
                disabled
              >
                No RESUME
              </Button>
            )}
          </>
        );
      case 8:
        return (
          <>
            <Typography
              style={{ color: "black", fontWeight: "600", marginTop: 10 }}
            >
              {index + 1 + ". " + que.name}
            </Typography>
            <Typography style={{ fontSize: 16 }}>{que.values[0]}</Typography>
          </>
        );

      case 7:
        return (
          <>
            <Typography
              style={{ color: "black", fontWeight: "600", marginTop: 10 }}
            >
              {index + 1 + ". " + que.name}
            </Typography>
            <Typography style={{ fontSize: 16 }}>{que.values[0]}</Typography>
          </>
        );
    }
  }

  const viewResumeHandler = () => {
    dispatch(getResumeToken());
    setOpenModal(true);
    setResume(data?.resume);
  };

  const downloadResumeHandler = (link?: string) => {
    dispatch(getResumeToken());
    setType("download");
    window.open(
      (link ? link : data?.resume) +
        "&access_token=" +
        resumeAccess.access_token,
      "_blank"
    );
  };

  function onDocumentLoadSuccess(props: any) {
    setNumPages(props.numPages);
  }

  return (
    <div className="candidate-details-card">
      <h1>{events ? " Attendee Details" : "Candidate Submission"}</h1>
      <div className="banner">
        <img className="cover-img" src={banner} alt="banner" />
        {data?.created_by?.socials &&
          data.created_by.socials[0]?.url?.includes("linkedin") && (
            <a href={data.created_by.socials[0]?.url} target="_blank">
              <Button>View LinkedIn Profile</Button>
            </a>
          )}
      </div>

      <div className="lower">
        <div className="resume">
          <div>
            {data?.created_by?.display_pictures === null ||
            data?.created_by?.display_pictures?.length === 0 ? (
              <Avatar className="profile-img avatar">
                {data?.created_by?.first_name.at(0)}
              </Avatar>
            ) : (
              <img
                className="profile-img"
                src={data?.created_by?.display_pictures[0]}
                alt="Profile Image"
              />
            )}
            <h4>
              {data?.created_by?.first_name + " " + data?.created_by?.last_name}
            </h4>
          </div>

          {!events && (
            <div className="view">
              <Button className="view-btn" onClick={viewResumeHandler}>
                View Resume
              </Button>

              <Button
                onClick={() => downloadResumeHandler()}
                className="download-btn"
              >
                Download Resume
              </Button>
            </div>
          )}
        </div>
        <div className="scrollable">
          <BasicDetails data={events ? data?.full_user : data} />

          {(data?.job_questions?.length > 0 ||
            data?.event_questions?.length > 0) && (
            <div className="questions">
              <h4>Questions</h4>
              {events
                ? data?.event_questions?.map((que: any, index: number) => (
                    <div className="que">{getQuestion(que, index)}</div>
                  ))
                : data?.job_questions?.map((que: any, index: number) => (
                    <div className="que">{getQuestion(que, index)}</div>
                  ))}
            </div>
          )}
        </div>
      </div>

      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          setResume("");
        }}
      >
        <Box sx={modal}>
          <Document
            file={`${resume}&access_token=${resumeAccess?.access_token}`}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page pageNumber={pageNumber} />
          </Document>
        </Box>
      </Modal>
    </div>
  );
};

export default CandidateDetailCard;

const modal = {
  display: "flex",
  justifyContent: "center",
  margin: "auto",
  backgroundColor: "white",
  width: "fit-content",
  height: "1240px",
  overflowY: "scroll",
  minWidth: "500px",
  maxWidth: "874px",
  maxHeight: "90vh",
  borderRadius: "10px",
  marginTop: "50px",
};
