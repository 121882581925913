import axios from "../axiosInterceptor";
import {
  EVENT_LIST_REQUEST,
  EVENT_LIST_SUCCESS,
  EVENT_LIST_FAIL,
  EVENT_ATTENDEE_DETAIL_REQUEST,
  EVENT_ATTENDEE_DETAIL_SUCCESS,
  EVENT_ATTENDEE_DETAIL_FAIL,
  EVENT_DATA_REQUEST,
  EVENT_DATA_SUCCESS,
  EVENT_DATA_FAIL,
  EVENT_SUMMARY_REQUEST,
  EVENT_SUMMARY_SUCCESS,
  EVENT_SUMMARY_FAIL,
  PAYMENT_STATUS_REQUEST,
  PAYMENT_STATUS_SUCCESS,
  PAYMENT_STATUS_FAIL
} from "../Constants/eventConstants";

const BASE = process.env.REACT_APP_BACKEND;

export const getEventAttendeeDetail = (id: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: EVENT_ATTENDEE_DETAIL_REQUEST,
    });

    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token")!)
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    };

    const { data } = await axios.get(
      `${BASE}/api/v1/registration/${id}`,
      config
    );

    // Dispatch the success action
    dispatch({
      type: EVENT_ATTENDEE_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: EVENT_ATTENDEE_DETAIL_FAIL,
      payload: error.message,
    });
  }
};

export const getEventList = () => async (dispatch: any) => {
  try {
    dispatch({
      type: EVENT_LIST_REQUEST,
    });

    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token")!)
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    };

    const { data } = await axios.get(`${BASE}/api/v1/event/user`, config);

    // Dispatch the success action
    dispatch({
      type: EVENT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: EVENT_LIST_FAIL,
      payload: error.message,
    });
  }
};

export const getEventData = (id: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: EVENT_DATA_REQUEST,
    });

    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token")!)
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    };

    const { data } = await axios.get(
      `${BASE}/api/v1/registration/event/${id}`,
      config
    );

    // Dispatch the success action
    dispatch({
      type: EVENT_DATA_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: EVENT_DATA_FAIL,
      payload: error.message,
    });
  }
};

export const getEventSummary = (id: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: EVENT_SUMMARY_REQUEST,
    });

    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token")!)
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    };

    const { data } = await axios.get(
      `${BASE}/api/v1/registration/summary/${id}`,
      config
    );

    // Dispatch the success action
    dispatch({
      type: EVENT_SUMMARY_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: EVENT_SUMMARY_FAIL,
      payload: error.message,
    });
  }
};


export const paymentStatus = (payment: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: PAYMENT_STATUS_REQUEST,
    });

    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token")!)
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    };


    const { data } = await axios.put(
      `${BASE}/api/v1/registration/update/payment_status`,payment,
      config
    );

    // Dispatch the success action
    dispatch({
      type: PAYMENT_STATUS_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: PAYMENT_STATUS_FAIL,
      payload: error.message,
    });
  }
};
