import { useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "../App.scss";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "../theme";

import SignIn from "../pages/Signin/SignIn";
import ForgetPassword from "../pages/Signin/ForgetPassword";

import Candidates from "../pages/Job/Candidates";
import JobDashboard from "../pages/JobDashboard/JobDashboard";

import EventSummary from "../pages/Dashboard/EventSummary";
import Summary from "../pages/Dashboard/Summary";
import Events from "../pages/Event/Events";

import ChangePassword from "../pages/Settings/ChangePassword";

import EventStatus from "../pages/Event/event-status";

import Dashboard from "../pages/Dashboard";

const ProtectedRoute = ({
  user,
  next,
  children,
}: {
  user: any;
  next: string;
  children: any;
}) => {
  if (user) {
    return children;
  }

  return <Navigate to={`/signin?next=${next}`} replace />;
};

const AppRoutes = () => {
  const { userInfo } = useSelector((state: any) => state.userDetails);
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route path="/" element={<Navigate to="/signin" replace />} />

          <Route path="/signin" element={<SignIn />} />
          <Route path="/signin/forget-password" element={<ForgetPassword />} />

          <Route
            path="/jobs"
            element={
              <ProtectedRoute user={userInfo} next="/jobs">
                <Candidates />
              </ProtectedRoute>
            }
          />

          <Route
            path="/events"
            element={
              <ProtectedRoute user={userInfo} next="/events">
                <Events />
              </ProtectedRoute>
            }
          />

          <Route
            path="/dashboard/event-summary"
            element={
              <ProtectedRoute user={userInfo} next="/dashboard/event-summary">
                <EventSummary />
              </ProtectedRoute>
            }
          />
          <Route
            path="/job-dashboard"
            element={
              <ProtectedRoute user={userInfo} next="/job-dashboard">
                <JobDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/summary"
            element={
              <ProtectedRoute user={userInfo} next="/summary">
                <Summary />
              </ProtectedRoute>
            }
          />

          <Route
            path="/Dashboard"
            element={
              <ProtectedRoute user={userInfo} next="/summary">
                <Dashboard />
              </ProtectedRoute>
            }
          />

          <Route
            path="/settings/change-password"
            element={
              <ProtectedRoute user={userInfo} next="/settings/change-password">
                <ChangePassword />
              </ProtectedRoute>
            }
          />

          <Route
            path="/status"
            element={
              <ProtectedRoute user={userInfo} next="status">
                <EventStatus />
              </ProtectedRoute>
            }
          />

          <Route path="*" element={<Navigate to="/signin" replace />} />
        </Routes>
      </ThemeProvider>
    </Router>
  );
};

export default AppRoutes;
