import React from "react";
import Search from "@mui/icons-material/Search";
import LayoutsSide from "../../Component/Layout/LayoutsSide";
import { FormControl, MenuItem, Select } from "@mui/material";

const EventStatus = () => {
  return (
    <div className="event-status">
      <div className="top">
        <div className="text">
          <h2>Event name...</h2> <h1> / Payment Gateway</h1>
        </div>

        <div className="search-button">
          <div className="search">
            <Search className="icon" />
            <input type="search" placeholder="Search for candidate" />
          </div>
          <div className="select">
          <FormControl fullWidth>
            <Select
             size="small"
             className="select"
            // value={age}
            // label="Age"
            // onChange={handleChange}
            >
              <MenuItem value={10}>filter</MenuItem>
              
            </Select>
          </FormControl>
          </div>
          <button className="button"> Apply </button>
        </div>
      </div>
    </div>
  );
};

export default LayoutsSide(EventStatus);
