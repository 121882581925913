import {
  EVENT_ATTENDEE_DETAIL_REQUEST,
  EVENT_ATTENDEE_DETAIL_SUCCESS,
  EVENT_ATTENDEE_DETAIL_FAIL,
  EVENT_LIST_REQUEST,
  EVENT_LIST_SUCCESS,
  EVENT_LIST_FAIL,
  EVENT_DATA_REQUEST,
  EVENT_DATA_SUCCESS,
  EVENT_DATA_FAIL,
  EVENT_SUMMARY_REQUEST,
  EVENT_SUMMARY_SUCCESS,
  EVENT_SUMMARY_FAIL,
  PAYMENT_STATUS_REQUEST,
  PAYMENT_STATUS_SUCCESS,
  PAYMENT_STATUS_FAIL
} from "../Constants/eventConstants";

export const eventAttendeeDetailReducer = (
  state = {},
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case EVENT_ATTENDEE_DETAIL_REQUEST:
      return { loading: true };

    case EVENT_ATTENDEE_DETAIL_SUCCESS:
      return {
        eventAttendee: action.payload,
        loading: false,
        error: false,
      };

    case EVENT_ATTENDEE_DETAIL_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const eventListReducer = (
  state = {},
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case EVENT_LIST_REQUEST:
      return { loading: true };

    case EVENT_LIST_SUCCESS:
      return {
        eventLists: action.payload,
        loading: false,
        error: false,
      };

    case EVENT_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const eventDataReducer = (
  state = {},
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case EVENT_DATA_REQUEST:
      return { loading: true };

    case EVENT_DATA_SUCCESS:
      return {
        events: action.payload,
        loading: false,
        error: null,
      };

    case EVENT_DATA_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const eventSummaryReducer = (
  state = {},
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case EVENT_SUMMARY_REQUEST:
      return { loading: true };

    case EVENT_SUMMARY_SUCCESS:
      return {
        eventSummary: action.payload,
        loading: false,
        error: null,
      };

    case EVENT_SUMMARY_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};


export const paymentStatusReducer = (
  state = {},
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case PAYMENT_STATUS_REQUEST:
      return { loading: true };

    case PAYMENT_STATUS_SUCCESS:
      return {
        Status: action.payload,
        loading: false,
        error: null,
      };

    case PAYMENT_STATUS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};