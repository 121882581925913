import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  CircularProgress,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  DataGridPro,
  useGridApiRef,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";

import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import Search from "@mui/icons-material/Search";

import { paymentStatus } from "../../redux/Actions/eventActions";
import { ArrowBackIos } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

function escapeRegExp(value: string): string {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

interface GridPaginationSettings {
  pagination: boolean;
  autoPageSize: boolean;
  pageSize: number | undefined;
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
    </GridToolbarContainer>
  );
}

const columns = [
  {
    field: "full_name",
    headerName: "Full Name",
    width: 260,
    editable: false,
  },
  {
    field: "email",
    headerName: "Email",
    width: 260,
    editable: false,
  },
  {
    field: "phone",
    headerName: "Phone No",

    width: 300,
    editable: false,
  },
];
const columnGroup = [
  {
    field: "full_name",
    headerName: "Full Name",
    width: 270,
    editable: false,
  },
  {
    field: "email",
    headerName: "Email",
    width: 270,
    editable: false,
  },
  {
    field: "phone",
    headerName: "Phone No",

    width: 380,
    editable: false,
  },
  {
    field: "payment_status",
    headerName: "Payment",

    width: 380,
    editable: false,
  },
];
const INITIAL_GROUPING_COLUMN_MODEL = ["full_name"];
const EventStatus: React.FC<{
  events: any;
  loading: any;
  eventId: any;
  eventLists?: any;
}> = ({ events, loading, eventId, eventLists }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const apiRef = useGridApiRef();

  const { Status, loading: statusLoading } = useSelector(
    (state: any) => state.paymentsStatus
  );
  const { userInfo } = useSelector((state: any) => state.userDetails);
  const [row, setRow] = useState([]);
  const [col, setCol] = useState([]);
  const [isLoading, setIsLoading] = useState<any>({
    loading: statusLoading,
    id: "",
  });
  const [payStatus, setPayStatus] = useState<any>("0");
  const [searchText, setSearchText] = React.useState("");
  const [eventName, setEventName] = useState<any>();
  const [searchRow, setSearchRow] = useState([]);
  const [rowId, setRowId] = useState<any>();
  const [payment, setPayment] = useState<any>(true);
  const [group, setGroup] = useState<any>("1");
  const [Table, setTable] = useState<any>("1");
  const [rowGroupingModel, setRowGroupingModel] = React.useState(
    INITIAL_GROUPING_COLUMN_MODEL
  );
  const [data, setData] = useState({
    event_id: eventId,
    payment_status: "",
    reg_ids: [],
  });

  useEffect(() => {
    eventLists?.records?.map((event: any) => {
      if (event?._id === eventId) {
        setEventName(event?.name);
      }
    });
  }, [eventLists]);

  useEffect(() => {
    if (!loading) {
      let tempCol: any = [...columns];
      tempCol.push({
        field: "payment_status",
        headerName: "Payment",
        renderCell: (params: any) => (
          <>
            <div>
              <FormControl>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  size="small"
                  value={params?.row?.payment_status}
                  onChange={(e) => stausHandler(e, params)}
                  style={
                    params?.row?.payment_status === "Unpaid"
                      ? { color: "#FEB702", width: "160px", fontWeight: "500" }
                      : params?.row?.payment_status === "Paid"
                      ? { color: "#009D06", width: "160px", fontWeight: "500" }
                      : params?.row?.payment_status === "Due"
                      ? { color: "#EA3800", width: "160px", fontWeight: "500" }
                      : { color: "#555555", width: "160px", fontWeight: "500" }
                  }
                >
                  {params?.row?.payment?.map((item: any, index: any) => (
                    <MenuItem
                      value={item}
                      style={
                        item === "Unpaid"
                          ? { color: "#FEB702" }
                          : item === "Paid"
                          ? { color: "#009D06" }
                          : item === "Due"
                          ? { color: "#EA3800" }
                          : { color: "#555555" }
                      }
                    >
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {isLoading.loading && isLoading?.id === params?.row?._id ? (
                <CircularProgress
                  style={{
                    width: "20px",
                    height: "20px",
                    marginLeft: "-50px",
                    marginTop: "10px",
                  }}
                />
              ) : null}
            </div>
          </>
        ),

        width: 270,
        editable: false,
      });

      setCol(tempCol);

      setIsLoading({
        ...isLoading,
        loading: false,
      });
    }
  }, [events, Status]);

  useEffect(() => {
    if (!loading) {
      let data: any = [];
      let option: any = ["Unpaid", "Paid", "Due", "Unqualified"];
      events?.records?.map((item: any, index: any) => {
        const id = item._id;
        const name = item?.created_by?.first_name + item?.created_by?.last_name;
        const email = item?.created_by?.email;
        const phone = item?.created_by?.mobile;
        const value = item?.payment_status;
        const loading = false;
        setPayment(item?.payment_status);

        data?.push({
          _id: id,
          full_name: name,
          email: email,
          phone: phone,
          payment: option,
          payment_status: value,
        });
      });

      setRow(data);
      setSearchRow(data);
    }
  }, [events]);

  useEffect(() => {
    setRow(row);
    if (searchText === "") {
      setRow(searchRow);
    }
  }, [row]);

  const stausHandler = (e: any, params: any) => {
    let id: any = [];
    id[0] = params.row._id;
    setPayment(e.target.value);
    setIsLoading({
      loading: true,
      id: params.row._id,
    });
    dispatch(
      paymentStatus({
        event_id: eventId,
        payment_status: e.target.value,
        reg_ids: id,
      })
    );
  };

  const checkHandler = (params: any) => {
    setRowId(params);
  };

  const bulkHandleChange = (e: any) => {
    setPayStatus(e.target.value);
    dispatch(
      paymentStatus({
        event_id: eventId,
        payment_status: e.target.value,
        reg_ids: rowId,
      })
    );
  };

  const SarchHandler = (e: any) => {
    setSearchText(e.target.value);
    const searchRegex = new RegExp(escapeRegExp(searchText), "i");
    const filteredRows = row?.filter((row: any) => {
      return Object?.keys(row)?.some((field: any) => {
        return searchRegex?.test(row[field]?.toString());
      });
    });
    setRow(filteredRows);
  };

  const clearSearch = () => {
    setSearchText("");
    setRow(searchRow);
  };

  const GroupSelectHandleChange = (e: any) => {
    setGroup(e.target.value);
  };
  const OnApplyHandler = () => {
    setTable(group);
  };
  const [pagination, setPagination] = React.useState<GridPaginationSettings>({
    pagination: false,
    autoPageSize: false,
    pageSize: undefined,
  });

  return (
    <div className="event-status">
      <div className="top">
        <div className="text">
          <ArrowBackIos
            style={{ color: "#777", cursor: "pointer" }}
            onClick={() => navigate(`?view=list&display=all&id=${eventId}`)}
          />
          <h2
            style={{ color: "#777", cursor: "pointer" }}
            onClick={() => navigate(`?view=list&display=all&id=${eventId}`)}
          >
            {eventName?.length > 40
              ? eventName.slice(0, 40) + "..."
              : eventName}
          </h2>{" "}
          <h1 style={{ color: "#555" }}>/ Payment Status </h1>
        </div>

        <div className="search-button">
          <Box>
            <TextField
              className="search"
              variant="standard"
              value={searchText}
              onChange={SarchHandler}
              inputProps={{ style: { fontSize: 14 } }}
              placeholder="Search for candidate "
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <SearchIcon
                    style={{
                      color: "#999999",
                      fontSize: "22px",
                      marginRight: "10px",
                    }}
                  />
                ),
                endAdornment: (
                  <IconButton
                    title="Clear"
                    aria-label="Clear"
                    size="small"
                    style={{ visibility: searchText ? "visible" : "hidden" }}
                    onClick={clearSearch}
                  >
                    <ClearIcon
                      style={{
                        color: "#999999",
                        fontSize: "22px",
                        marginRight: "10px",
                      }}
                    />
                  </IconButton>
                ),
              }}
            />
          </Box>

          <div className="select">
            <FormControl fullWidth>
              <Select
                size="small"
                className="select"
                value={group}
                onChange={GroupSelectHandleChange}
              >
                <MenuItem value="1">List view</MenuItem>
                <MenuItem value="2">Group view</MenuItem>
              </Select>
            </FormControl>
          </div>
          <button
            className="button"
            onClick={OnApplyHandler}
            style={{ background: "#8046C4" }}
          >
            {" "}
            Apply{" "}
          </button>
        </div>
      </div>

      {rowId?.length > 0 && (
        <div className="bluck">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p>Change payment status for selected:</p>
            <p className="selected">{rowId?.length} rows Selected</p>
          </div>

          <FormControl>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={payStatus}
              size="small"
              onChange={bulkHandleChange}
              style={
                payStatus === "Unpaid"
                  ? { color: "#FEB702", width: "140px", fontWeight: "500" }
                  : payStatus === "Paid"
                  ? { color: "#009D06", width: "140px", fontWeight: "500" }
                  : payStatus === "Due"
                  ? { color: "#EA3800", width: "140px", fontWeight: "500" }
                  : { color: "#555555", width: "140px", fontWeight: "500" }
              }
            >
              <MenuItem value={0} disabled>
                Select
              </MenuItem>
              {option.map((opt: any, index: any) => (
                <MenuItem
                  value={opt}
                  style={
                    opt === "Unpaid"
                      ? { color: "#FEB702", width: "140px", fontWeight: "500" }
                      : opt === "Paid"
                      ? { color: "#009D06", width: "140px", fontWeight: "500" }
                      : opt === "Due"
                      ? { color: "#EA3800", width: "140px", fontWeight: "500" }
                      : { color: "#555555", width: "140px", fontWeight: "500" }
                  }
                >
                  {opt}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      )}

      <div className="table">
        {Table === "1" && (
          <Box sx={{ height: 600, width: "91%" }}>
            <DataGridPro
              rows={row}
              getRowId={(row) => row._id}
              columns={col}
              loading={loading}
              components={{
                Toolbar: CustomToolbar,
              }}
              rowThreshold={0}
              paginationMode="server"
              checkboxSelection
              onSelectionModelChange={checkHandler}
              disableSelectionOnClick
              {...pagination}
            />
          </Box>
        )}

        {Table === "2" && (
          <Box sx={{ height: 600, width: "91%" }}>
            <DataGridPro
              getRowId={(row) => row._id}
              rows={row}
              components={{
                Toolbar: CustomToolbar,
              }}
              apiRef={apiRef}
              columns={columnGroup}
              rowGroupingModel={["payment_status"]}
              experimentalFeatures={{
                rowGrouping: true,
              }}
            />
          </Box>
        )}
      </div>
    </div>
  );
};

export default EventStatus;
const option = ["Unpaid", "Paid", "Due", "Unqualified"];
