import { createTheme } from "@mui/material/styles";
import { green, grey, red } from "@mui/material/colors";
import { Theme } from "@mui/material/styles";

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

const rawTheme = createTheme({
  palette: {
    primary: {
      light: "#7f00bb",
      main: "#7f00bb",
      dark: "#7f00bb",
    },
    secondary: {
      light: "#000000",
      main: "#000000",
      dark: "#000000",
    },
    warning: {
      main: "#ffc071",
      dark: "#ffb25e",
    },
    error: {
      main: red[500],
      dark: red[700],
    },
    success: {
      main: green[500],
      dark: green[700],
    },
  },
  typography: {
    fontFamily: "Poppins, sans-serif",
    fontSize: 14,
    fontWeightLight: 200, // Work Sans
    fontWeightRegular: 300, // Work Sans
    fontWeightMedium: 400, // Roboto Condensed
    // fontFamilySecondary: "'Roboto Condensed', sans-serif",
  },
  // button: {

  // }
});

const fontHeader = {
  color: rawTheme.palette.text.primary,
  fontWeight: rawTheme.typography.fontWeightMedium,
//   fontFamily: rawTheme.typography.fontFamilySecondary,
  // textTransform: 'uppercase',
};

const theme = {
  ...rawTheme,
  palette: {
    ...rawTheme.palette,
    background: {
      ...rawTheme.palette.background,
      default: rawTheme.palette.common.white,
      placeholder: grey[200],
    },
  },
  typography: {
    ...rawTheme.typography,
    fontHeader,
    h1: {
      ...rawTheme.typography.h1,
      ...fontHeader,
      color: "#fff",
      fontSize: "60px",
      lineHeight: "60px",
      fontWeight: "100",
      letterSpacing: "4px",
    },
    h2: {
      ...rawTheme.typography.h2,
      ...fontHeader,
      fontSize: 48,
    },
    h3: {
      ...rawTheme.typography.h3,
      ...fontHeader,
      fontSize: 42,
    },
    h4: {
      ...rawTheme.typography.h4,
      ...fontHeader,
      fontSize: 36,
    },
    h5: {
      ...rawTheme.typography.h5,
      fontSize: 25,
      fontWeight: "300",
    },
    h6: {
      ...rawTheme.typography.h6,
      ...fontHeader,
      fontSize: 18,
    },
    subtitle1: {
      ...rawTheme.typography.subtitle1,
      fontSize: 18,
    },
    body1: {
      ...rawTheme.typography.body2,
      fontWeight: rawTheme.typography.fontWeightRegular,
      fontSize: 16,
    },
    body2: {
      ...rawTheme.typography.body1,
      fontSize: 14,
    },
  },
};

export default theme;