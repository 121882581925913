import { useEffect, useState } from "react";
import { Button, FormControl, MenuItem, Select } from "@mui/material";
import {
  jobAnalyticsMiscellaneous,
  jobAnalytics,
} from "../../../redux/Actions/JobAnalyticAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getEventSummary } from "../../../redux/Actions/eventActions";

const HeaderSection: React.FC<{
  listData: any;
  setId: any;
  setTimeFilter: any;
  DataType: any;
}> = ({ listData, setId, setTimeFilter, DataType }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  let view = searchParams.get("view");
  let id = searchParams.get("id");

  const { jobDataMiscellances, loading, error } = useSelector(
    (state: any) => state.jobDataAnalyticsMiscellaneous
  );
  const { eventSummary, loading: loadingSummary } = useSelector(
    (state: any) => state.eventSummary
  );

  let Total_Attendees = 0;
  let Total_University = 0;
  Total_Attendees = eventSummary?.total_count;
  Total_University = eventSummary?.count;

  const [data, setData] = useState<any>({
    id: id,
    startTime: 0,
    endTime: 9999999999,
    analyticsType: DataType,
  });
  const [active, setActive] = useState<any>(9999999999);
  const [miscellancesData, setMiscellancesData] = useState<any>();

  useEffect(() => {
    if (id) {
      dispatch(
        jobAnalyticsMiscellaneous({
          ...data,
          analyticsType: DataType,
        })
      );
    }
  }, [id, DataType]);

  useEffect(() => {
    if (jobDataMiscellances) {
      let sorteddata = jobDataMiscellances?.customLables?.sort(
        (a: any, b: any) => {
          return a.rank - b.rank;
        }
      );
      setMiscellancesData(sorteddata);
    }
  }, [jobDataMiscellances]);

  const selectJobHandler = (event: any) => {
    setId(event.target.value as string);
    navigate(`?view=${view}&id=${event.target.value}`);
    // window.location.reload();
    setData({
      ...data,
      id: event.target.value,
    });
  };

  const timeHandler = (type: any) => {
    setActive(type);
    var d = new Date();
    var ts = Math.floor(Date.now() / 1000);
    var endTime = Math.floor((Date.now() - type * 24 * 60 * 60 * 1000) / 1000);
    if (type === 9999999999) {
      setTimeFilter(9999999999);
    } else {
      setTimeFilter(endTime);
    }

    dispatch(
      jobAnalyticsMiscellaneous({
        ...data,
        startTime: type === 9999999999 ? 0 : endTime,
        endTime: type === 9999999999 ? 9999999999 : ts,
        analyticsType: DataType,
      })
    );
  };

  return (
    <div className="header-section">
      <div className="select-time">
        <FormControl style={{ width: "50%" }}>
          <Select
            defaultValue={id === null ? 0 : id}
            style={{ height: "48px" }}
            onChange={selectJobHandler}
            MenuProps={{
              PaperProps: {
                sx:
                  view === "jobs"
                    ? { maxHeight: 500, maxWidth: 400 }
                    : { maxHeight: "auto", maxWidth: "auto" },
              },
            }}
          >
            <MenuItem value={0} disabled>
              {view === "jobs" ? "Select Jobs" : "Select Events"}
            </MenuItem>
            {listData?.records?.map((application: any) => (
              <MenuItem value={application._id}>
                {application.job_title === undefined
                  ? application.organisations[0] + " - " + application?.name
                  : application.organisations[0] +
                    " - " +
                    application.job_title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {id != null && (
          <div className="button-group">
            {/* <Button
              className={active === 1 ? "button active-first" : "button"}
              onClick={() => timeHandler(1)}
            >
              24 hours
            </Button> */}
            {/* <Button
              className={active === 7 ? "button active" : "button"}
              onClick={() => timeHandler(7)}
            >
              7 days
            </Button> */}
            <Button
              className={active === 30 ? "button active" : "button"}
              onClick={() => timeHandler(30)}
            >
              30 days
            </Button>
            <Button
              className={
                active === 9999999999 ? "button active-last" : "button"
              }
              onClick={() => timeHandler(9999999999)}
            >
              All
            </Button>
          </div>
        )}
      </div>
      {id != null && (
        <div className="card-body">
          {jobDataMiscellances?.view?.visible && (
            <div className="card">
              <h4>{jobDataMiscellances?.view?.label}</h4>
              <h1>{jobDataMiscellances?.view?.count}</h1>
            </div>
          )}
          {jobDataMiscellances?.click?.visible && (
            <div className="card">
              <h4>T {jobDataMiscellances?.click?.label}</h4>
              <h1 style={{ color: "#229651" }}>
                {jobDataMiscellances?.click?.count}
              </h1>
            </div>
          )}
          {jobDataMiscellances?.apply?.visible && (
            <div className="card">
              <h4>{jobDataMiscellances?.apply?.label}</h4>
              <h1 style={{ color: "#121212" }}>
                {jobDataMiscellances?.apply?.count}
              </h1>
            </div>
          )}

          {miscellancesData?.map((item: any, index: any) => (
            <div className="card">
              <h4>{item?.displayName}</h4>
              <h1 style={{ color: "#121212" }}>{item?.value}</h1>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default HeaderSection;
