import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userPasswordUpdate } from "../../redux/Actions/userActions";

import {
  TextField,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
  Divider
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { strongPassword } from "../../helpers/checks/BasicChecks";
import LayoutsSide from "../../Component/Layout/LayoutsSide";

const ChangePassword = () => {
  const dispatch = useDispatch();

  const { success, error, loading } = useSelector(
    (state: any) => state.userPasswordChange
  );

  const [current, setCurrent] = useState("");
  const [pass, setPass] = useState("");
  const [confirm, setConfirm] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showCurrPassword, setShowCurrPassword] = useState(false);

  const clickHandler = () => {
    if (current !== "" && strongPassword.test(pass) && pass === confirm) {
      dispatch(
        userPasswordUpdate({
          old_password: current,
          new_password: pass,
          confirm_password: confirm,
        })
      );
    }
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (error || success) {
      setCurrent("");
      setPass("");
      setConfirm("");
    }
  }, [error, success]);

  return (
    <div className="change-password">
      <h1>Change password</h1>
      <div className="input-fields">
        <FormControl variant="outlined" fullWidth>
          <InputLabel>Current Password</InputLabel>
          <OutlinedInput
            label="Current Password"
            type={showCurrPassword ? "text" : "password"}
            value={current}
            onChange={(e) => setCurrent(e.target.value)}
            sx={{
              width: "100%",
              horizontalAlignment: "upper",
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowCurrPassword(!showCurrPassword)}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {!showCurrPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>

        <Divider />

        <FormControl variant="outlined" fullWidth>
          <InputLabel>New Password</InputLabel>
          <OutlinedInput
            label="New Password"
            type={showPassword ? "text" : "password"}
            value={pass}
            onChange={(e) => setPass(e.target.value)}
            error={pass !== "" && !strongPassword.test(pass)}
            sx={{
              width: "100%",
              horizontalAlignment: "upper",
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {!showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText>
            {pass !== "" && !strongPassword.test(pass)
              ? "Min 8 Characters with 1 Uppercase, 1 Lowercase, 1 Number and 1 Symbol"
              : ""}
          </FormHelperText>
        </FormControl>

        <FormControl variant="outlined" fullWidth>
          <InputLabel>Confirm Password</InputLabel>
          <OutlinedInput
            label="Confirm New Password"
            type={showPassword ? "text" : "password"}
            value={confirm}
            onChange={(e) => setConfirm(e.target.value)}
            error={confirm !== "" && confirm !== pass}
            sx={{
              width: "100%",
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {!showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText>
            {confirm !== "" && confirm !== pass
              ? "Passwords doesn't match"
              : ""}
          </FormHelperText>
        </FormControl>
      </div>
      {error && <p style={{ color: "red" }}>{error.data.message}</p>}
      {success && <p>Password changed successfully!</p>}
      {loading ? (
        <CircularProgress />
      ) : (
        <Button onClick={clickHandler} variant="contained">
          Change
        </Button>
      )}
    </div>
  );
};

export default LayoutsSide(ChangePassword);
