import { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";

import {
  WorkOutline,
  GroupOutlined,
  EventOutlined,
  Settings,
  ArrowBackIosNew,
  ArrowForwardIos,
  Logout,
} from "@mui/icons-material";
import { Button, Paper } from "@mui/material";

import { useDispatch } from "react-redux";
import { signOut } from "../../redux/Actions/userActions";

import main from "../../assets/images/logos/logo.png";
import small from "../../assets/images/logos/favicon.png";

const publicRoutes = [
  {
    path: "/dashboard",
    pathName: "Dashboard",
    logo: <GroupOutlined />,
  },

  {
    path: "/events",
    pathName: "Events",
    logo: <EventOutlined />,
  },
  {
    path: "/jobs",
    pathName: "Jobs",
    logo: <WorkOutline />,
  },
  {
    path: "/settings/change-password",
    pathName: "Password",
    logo: <Settings />,
  },
];

const SideBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [link, setLink] = useState("");
  const [open, setOpen] = useState<Boolean>();
  const [active, setActive] = useState(1);
  const size = window.screen.availWidth;

  useEffect(() => {
    setLink(location.pathname);
    if (size > 750 && size < 1100) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, []);

  return (
    <Paper variant="outlined" className="sidebar">
      <div className={open ? "open" : "collapsed"}>
        <div className="logo">
          {open ? (
            <img src={main} alt="ELEVATE" />
          ) : (
            <img style={{ maxWidth: "60px" }} src={small} alt="ELEVATE" />
          )}
        </div>

        <div className="menu">
          <ul>
            {publicRoutes.map((route, index) => (
              <li key={index} className={link === route.path ? "active" : ""}>
                <Link onClick={() => setLink(route.path)} to={route.path}>
                  {" "}
                  {route.logo}
                  {open && route.pathName}
                </Link>
              </li>
            ))}
            <li
              onClick={() => {
                dispatch(signOut());
                window.location.href = "/signin";
              }}
            >
              <Link to="">
                <Logout />
                {open && "Sign Out"}
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="collapse-btn">
        {size > 750 && size < 1100 ? (
          ""
        ) : (
          <Button onClick={() => setOpen(!open)}>
            {open ? <ArrowBackIosNew /> : <ArrowForwardIos />}
          </Button>
        )}
      </div>
    </Paper>
  );
};

export default SideBar;
