import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { useDispatch } from "react-redux";

import moment from "moment-timezone";

import { refreshToken } from "./Actions/userActions";

import {
  candidateDetailsReducer,
  bulkResumeReducer,
  statusJobReducer,
  scoreJobReducer,
  scoreJobParamReducer,
  scoreParamUpdateReducer,
  enableScoreReducer,
  jobListReducer,
  jobCandidateListReducer,
  jobSummaryReducer,
  resumeTokenReducer,
} from "./Reducers/jobReducers";

import {
  userSignInReducer,
  userDetailsReducer,
  userPasswordChangeReducer,
  userForgetMailReducer,
  userRefreshReducer,
} from "./Reducers/userReducers";
import {
  eventAttendeeDetailReducer,
  eventDataReducer,
  eventListReducer,
  eventSummaryReducer,
  paymentStatusReducer,
} from "./Reducers/eventReducers";

import {
  jobAnalyticsReducer,
  jobAnalyticsMiscellaneousReducer,
  jobAnalyticsDashBoardReducer,
} from "./Reducers/JobAnalyticReducers";

// REDUCERS GO HERE
const reducers = combineReducers({
  //USER
  signIn: userSignInReducer,
  userDetails: userDetailsReducer,
  userPasswordChange: userPasswordChangeReducer,
  userForgetMail: userForgetMailReducer,
  userRefresh: userRefreshReducer,

  //EVENTS
  eventList: eventListReducer,
  eventAttendeeDetail: eventAttendeeDetailReducer,
  eventData: eventDataReducer,
  eventSummary: eventSummaryReducer,
  paymentsStatus: paymentStatusReducer,

  //JOBS
  jobList: jobListReducer,
  jonCandidateList: jobCandidateListReducer,
  candidateDetails: candidateDetailsReducer,
  jobSummary: jobSummaryReducer,

  bulkResume: bulkResumeReducer,

  statusJob: statusJobReducer,
  scoreJob: scoreJobReducer,
  scoreJobParam: scoreJobParamReducer,
  scoreParamUpdate: scoreParamUpdateReducer,
  scoreEnable: enableScoreReducer,

  resumeToken: resumeTokenReducer,

  // job Analytics
  jobDataAnalyticsMiscellaneous: jobAnalyticsMiscellaneousReducer,
  jobDataAnalytics: jobAnalyticsReducer,
  jobDataAnalyticsDashBoard: jobAnalyticsDashBoardReducer,
});

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo")!)
  : null;

const tokenFromStorage = localStorage.getItem("token")
  ? JSON.parse(localStorage.getItem("token")!)
  : null;

const initialState = {
  // USE THIS TO INITIALIZE YOUR STATE
  userDetails: { userInfo: userInfoFromStorage },
  signIn: { token: tokenFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducers,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
