import {
  JOB_ANALYTICS_REQUEST,
  JOB_ANALYTICS_SUCCESS,
  JOB_ANALYTICS_FAIL,
  JOB_ANALYTICS_MISCELLANCEOUS_REQUEST,
  JOB_ANALYTICS_MISCELLANCEOUS_SUCCESS,
  JOB_ANALYTICS_MISCELLANCEOUS_FAIL,
  JOB_ANALYTICS_DASHBOARD_REQUEST,
  JOB_ANALYTICS_DASHBOARD_SUCCESS,
  JOB_ANALYTICS_DASHBOARD_FAIL
} from "../Constants/JobAnalyticConstants";

export const jobAnalyticsReducer = (
  state = {},
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case JOB_ANALYTICS_REQUEST:
      return { loading: true };

    case JOB_ANALYTICS_SUCCESS:
      return {
        jobData: action.payload,
        loading: false,
        error: null,
      };

    case JOB_ANALYTICS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const jobAnalyticsMiscellaneousReducer = (
  state = {},
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case JOB_ANALYTICS_MISCELLANCEOUS_REQUEST:
      return { loading: true };

    case JOB_ANALYTICS_MISCELLANCEOUS_SUCCESS:
      return {
        jobDataMiscellances: action.payload,
        loading: false,
        error: null,
      };

    case JOB_ANALYTICS_MISCELLANCEOUS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const jobAnalyticsDashBoardReducer = (
  state = {},
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case JOB_ANALYTICS_DASHBOARD_REQUEST:
      return { loading: true };

    case JOB_ANALYTICS_DASHBOARD_SUCCESS:
      return {
        jobDataDashBoard: action.payload,
        loading: false,
        error: null,
      };

    case JOB_ANALYTICS_DASHBOARD_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

