import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";
import CandidateDetails from "../../Component/Candidates/CandidateDetails";
import CandidateList from "../../Component/Candidates/CandidateList";

import { getCandidatesList, getJobList } from "../../redux/Actions/jobActions";

import LayoutsSide from "../../Component/Layout/LayoutsSide";

const Candidates = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [active, setActive] = useState(0);
  const [jobType, setJobType] = useState("");
  const [index, setIndex] = useState(-1);
  const [searchParams] = useSearchParams();
  let view = searchParams.get("view");
  let jobId = searchParams.get("jobId");

  useEffect(() => {
    if (index >= 0 && index < candidateList?.records.length) {
      navigate(
        `?view=details&jobId=${
          jobType === undefined ? jobId : jobType
        }&applicationId=${candidateList?.records[index]._id}&index=${index}`
      );
    }
  }, [index]);

  useEffect(() => {
    dispatch(getJobList());
  }, []);
  const { jobList, loading, error } = useSelector(
    (state: any) => state.jobList
  );
  useEffect(() => {
    dispatch(getCandidatesList(jobType));
  }, [jobType]);
  const { candidateList } = useSelector((state: any) => state.jonCandidateList);

  useEffect(() => {
    if (!location.search) {
      navigate("?view=list&display=all");
    } else if (view === "details") {
      setActive(1);
    } else if (view === "list") {
      setActive(0);
      setIndex(-1);
    }
  }, [location.search]);

  return (
    <div className="candidates">
      {active === 0 ? (
        <CandidateList
          candidateList={candidateList}
          jobList={jobList}
          loading={loading}
          jobType={jobType}
          setJobType={setJobType}
          setIndex={setIndex}
        />
      ) : (
        <CandidateDetails
          setIndex={setIndex}
          index={index}
          maxLength={candidateList?.records?.length}
        />
      )}
    </div>
  );
};

export default LayoutsSide(Candidates);
