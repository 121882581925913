export const USER_SIGNIN_REQUEST = "USER_SIGNIN_REQUEST";
export const USER_SIGNIN_SUCCESS = "USER_SIGNIN_SUCCESS";
export const USER_SIGNIN_FAIL = "USER_SIGNIN_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";

export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAIL = "USER_DETAILS_FAIL";

export const USER_FORGET_MAIL_REQUEST = "USER_FORGET_MAIL_REQUEST";
export const USER_FORGET_MAIL_SUCCESS = "USER_FORGET_MAIL_SUCCESS";
export const USER_FORGET_MAIL_FAIL = "USER_FORGET_MAIL_FAIL";

export const USER_PASSWORD_CHANGE_REQUEST = "USER_PASSWORD_CHANGE_REQUEST";
export const USER_PASSWORD_CHANGE_SUCCESS = "USER_PASSWORD_CHANGE_SUCCESS";
export const USER_PASSWORD_CHANGE_FAIL = "USER_PASSWORD_CHANGE_FAIL";

export const USER_REFRESH_REQUEST = "USER_REFRESH_REQUEST";
export const USER_REFRESH_SUCCESS = "USER_REFRESH_SUCCESS";
export const USER_REFRESH_FAIL = "USER_REFRESH_FAIL";
