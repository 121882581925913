import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getEventAttendeeDetail,
  getEventData,
} from "../../redux/Actions/eventActions";

import CandidateDetailCard from "../Candidates/CandidateDetailCard";

import { Button } from "@mui/material";

const useKeyPress = function (targetKey: any) {
  const [keyPressed, setKeyPressed] = useState(false);

  function leftHandler({ key }: any) {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  }

  const rightHandler = ({ key }: any) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", leftHandler);
    window.addEventListener("keyup", rightHandler);

    return () => {
      window.removeEventListener("keydown", leftHandler);
      window.removeEventListener("keyup", rightHandler);
    };
  });

  return keyPressed;
};

const EventCandidateDetails: React.FC<{
  setIndex: any;
  index: number;
  maxLength: number;
}> = ({ setIndex, index, maxLength }) => {
  const leftPress = useKeyPress("ArrowLeft");
  const rightPress = useKeyPress("ArrowRight");

  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const { eventAttendee } = useSelector(
    (state: any) => state.eventAttendeeDetail
  );

  let registrationId = searchParams.get("registrationId");
  let ind = searchParams.get("index");
  let eventId = searchParams.get("eventId");

  const { events } = useSelector((state: any) => state.eventData);

  useEffect(() => {
    if (index === -1) {
      setIndex(Number(ind));
    }
  }, []);

  useEffect(() => {
    if (events === undefined) {
      dispatch(getEventData(eventId));
    }
  }, []);

  useEffect(() => {
    dispatch(getEventAttendeeDetail(registrationId));
  }, [registrationId]);

  useEffect(() => {
    if (leftPress) {
      if (index > 0) {
        setIndex((prev: number) => prev - 1);
      } else if (index === 0) {
        setIndex(maxLength - 1);
      }
    }
  }, [leftPress]);

  useEffect(() => {
    if (rightPress) {
      if (index < maxLength - 1) {
        setIndex((prev: number) => prev + 1);
      } else if (index < maxLength) {
        setIndex(0);
      }
    }
  }, [rightPress]);

  const movLeft = () => {
    if (index > 0) {
      setIndex((prev: number) => prev - 1);
    } else if (index === 0) {
      setIndex(maxLength - 1);
    }
  };

  const movRight = () => {
    if (index < maxLength - 1) {
      setIndex((prev: number) => prev + 1);
    } else if (index < maxLength) {
      setIndex(0);
    }
  };

  return (
    <div className="candidate-details">
      <CandidateDetailCard
        data={eventAttendee ? eventAttendee : {}}
        events={true}
      />

      <div className="event-buttons">
        <Button onClick={movLeft}>Previous</Button>
        <Button onClick={movRight}>Next</Button>
      </div>
    </div>
  );
};

export default EventCandidateDetails;
