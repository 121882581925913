import {
  USER_SIGNIN_REQUEST,
  USER_SIGNIN_SUCCESS,
  USER_SIGNIN_FAIL,
  USER_LOGOUT,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  USER_FORGET_MAIL_REQUEST,
  USER_FORGET_MAIL_SUCCESS,
  USER_FORGET_MAIL_FAIL,
  USER_PASSWORD_CHANGE_REQUEST,
  USER_PASSWORD_CHANGE_SUCCESS,
  USER_PASSWORD_CHANGE_FAIL,
  USER_REFRESH_REQUEST,
  USER_REFRESH_SUCCESS,
  USER_REFRESH_FAIL,
} from "../Constants/userConstants";

export const userSignInReducer = (
  state = {},
  action: { type: string; payload: {} }
) => {
  switch (action.type) {
    case USER_SIGNIN_REQUEST:
      return { loading: true };

    case USER_SIGNIN_SUCCESS:
      return {
        token: action.payload,
        loading: false,
        isAuthenticated: true,
      };

    case USER_SIGNIN_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const userRefreshReducer = (
  state = {},
  action: { type: string; payload: {} }
) => {
  switch (action.type) {
    case USER_REFRESH_REQUEST:
      return { loading: true };

    case USER_REFRESH_SUCCESS:
      return {
        token: action.payload,
        loading: false,
        isAuthenticated: true,
      };

    case USER_REFRESH_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const userDetailsReducer = (
  state = {},
  action: { type: string; payload: {} }
) => {
  switch (action.type) {
    case USER_DETAILS_REQUEST:
      return { loading: true };

    case USER_DETAILS_SUCCESS:
      return {
        userInfo: action.payload,
        loading: false,
        isAuthenticated: true,
      };

    case USER_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    case USER_LOGOUT:
      return {};

    default:
      return state;
  }
};

export const userForgetMailReducer = (
  state = {},
  action: { type: string; payload: {} }
) => {
  switch (action.type) {
    case USER_FORGET_MAIL_REQUEST:
      return { loading: true };

    case USER_FORGET_MAIL_SUCCESS:
      return {
        success: true,
        loading: false,
      };

    case USER_FORGET_MAIL_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const userPasswordChangeReducer = (
  state = {},
  action: { type: string; payload: {} }
) => {
  switch (action.type) {
    case USER_PASSWORD_CHANGE_REQUEST:
      return { loading: true };

    case USER_PASSWORD_CHANGE_SUCCESS:
      return {
        success: true,
        loading: false,
      };

    case USER_PASSWORD_CHANGE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
